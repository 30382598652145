<template>
  <div class="position-relative">
    <swiper class="swiper" ref="swiper" :options="swiperOption">
      <swiper-slide class="box-road">
        <h3>THE ENCHANTEDS <span> MARCH-MAY 2022</span></h3>
        <ul>
          <li>
            5 Orders comprised of 2200 NFTS each, making up a full 11K
            collection.
          </li>
          <li>
            Fae, Jinn, Nymphs, Ephemera + a SURPRISE Order released one by one.
          </li>
          <li>
            Artifact holders &amp; existing Enchanted HODLers given priority
            access.
          </li>
          <li>
            Expect the unexpected! Mystical &amp; magical gifts between each
            drop!
          </li>
        </ul>
      </swiper-slide>
      <swiper-slide class="box-road">
        <h3>ENCHANTED EXPERIENCES <span>ONGOING</span></h3>
        <ul>
          <li>Monthly learning, healing, music and cultural events.</li>
          <li>Holiday parties and global festival celebrations.</li>
          <li>Fun community activities and competitions.</li>
          <li>IRL and Virtual events.</li>
        </ul>
      </swiper-slide>
      <swiper-slide class="box-road">
        <h3>ENCHANTED MERCHANDISE STORE <span>Q2 2022</span></h3>
        <ul>
          <li>IRL Apparel and accessories.</li>
          <li>Metaverse wearables fashion line.</li>
        </ul>
      </swiper-slide>
      <swiper-slide class="box-road">
        <h3 class="">
          ENCHANTED VALLEY FANTASY WORLD GROWTH STARTING <span>Q2 2022</span>
        </h3>
        <ul style="" class="ps-3">
          <li>
            Monthly story releases. Community-led story, art and music
            development.
          </li>
          <li>
            Future NFT collections airdrop and sales - e.g. animal companions,
            loot bags with wondrous possessions, spells etc. Rewards &amp;
            airdrops reserved especially for Artifact holders.
          </li>
          <li>
            Rewards &amp; airdrops reserved from future Enchanted Valley
            collections especially for Artifact holders.
          </li>
          <li>
            Existing token holders unlock access to pre-mint list if applicable.
          </li>
          <li>Enchanted Offsprings program.</li>
        </ul>
      </swiper-slide>
      <swiper-slide class="box-road">
        <h3>
          AR/VR/ METAVERSE: BRING THE WORLD TO LIFE - <span>IN PROGRESS</span>
        </h3>
        <ul>
          <li>
            Monthly events at the Art Bazaar - <br />
            <a
              style="color: #fae090; font-size: 1rem; font-style: italic"
              class="text-uppercase"
              href="https://www.cryptovoxels.com/play?coords=SE@1020E,26N,6F"
              target="_black"
              >VISIT OUR CRYPTOVOXELS SPACE.</a
            >
          </li>
          <li>Building the Enchanted Valley in the Metaverse.</li>
          <li>Metaverse wearable Enchanted Fashion line.</li>
          <li>Fantasy AR Filters.</li>
        </ul>
      </swiper-slide>
      <swiper-slide class="box-road">
        <h3>IMPACT - DIVERSITY, EQUITY, AND INCLUSION(DEI)</h3>
        <ul>
          <li>
            5% of net sales proceeds will be allocated to enable diversity &amp;
            inclusion in the NFT space through the Muse’s Chosen Program.
          </li>
          <li>Art Residencies to promote DEI in art + Web3 space.</li>
          <li>
            Grants &amp; Scholarships for Women, BIPOC, and LGBTQ+ artists to
            create in-universe art.
          </li>
        </ul>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev freccia-road" slot="button-prev">
      <img src="../assets/image/Group 143.svg" alt="" />
    </div>
    <div class="swiper-button-next freccia-road" slot="button-next">
      <img src="../assets/image/Group 142.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-change-direction",
  title: "Change direction",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        breakpoints: {
          1924: {
            slidesPerView: 2,
            spaceBetween: 40,
            autoplay:false
          },
          1399: {
            slidesPerView: 2,
            spaceBetween: 10,
            autoplay:false
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
            autoplay:false
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 60,
            autoplay:false
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay:true
          },
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        /*  on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        }, */
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
