<template>
  <div class="terms">
    <div class="container">
      <h1 class="text-center">TERMS OF USE</h1>
      <h3 class="text-center">
        THESE TERMS OF SALE AND USE (“THESE TERMS”) CONTAIN IMPORTANT
        INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS AS WELL AS CONDITIONS,
        LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ THEM
        CAREFULLY. BY PURCHASING OR USING IN ANY WAY THE ENCHANTEDS™ NFT
        COLLECTION THAT ORIGINATED FROM ENCHANTED STUDIOS LLC. THROUGH
        WWW.ENCHANTEDVALLEYNFT.COM (THE “WEBSITE”), YOU ACCEPT AND ARE BOUND BY
        THESE TERMS OF SALE.
      </h3>
      <p class="mt-4 mb-4">
        You should also carefully review our Terms of Service & our Privacy
        Policy before placing an order through the Website. By acquiring an NFT
        associated with Enchanted Studios through the website, or by
        subsequently acquiring any such NFT from any previous owner of the NFT,
        you agree to be bound by these Terms. <br /><br />This website is only
        an interface allowing participants to exchange blockchain-tracked
        non-fungible tokens (NFTs) from Enchanted Studios. Users are entirely
        responsible for the safety and management of their own private Ethereum
        wallets and validating all transactions and contracts generated by this
        website before approval. Furthermore, as The Enchanteds smart contract
        runs on the Ethereum network, there is no ability to undo, reverse, or
        restore any transactions. This website and its connected services are
        provided “as is” and “as available” without warranty of any kind. By
        using this website you are accepting sole responsibility for any and all
        transactions involving The Enchanteds digital collectibles.
      </p>
      <div class="mt-3">
        <h2 class="text-start">1. OWNERSHIP</h2>
        <p>
          <b>You Own Your NFT</b> . When you purchase an NFT, the ownership of
          the NFT is mediated entirely by the Smart Contract and the Ethereum
          Network: at no point may we seize, freeze, or otherwise modify the
          ownership of any of The Enchanteds NFTs. You acknowledge and agree
          that Enchanted Studios owns all legal rights, title and interest in
          and to the Art, and all intellectual property rights therein. “Art”
          means any art, design, and drawings that may be associated with an NFT
          that you Own. The rights that you have in and to the Art are limited
          to those described in this License. Enchanted Studios reserves all
          rights in and to the Art not expressly granted to you in this License.
        </p>
      </div>
      <div class="mt-3">
        <h2 class="text-start">2. LICENSE</h2>
        <p>
          <b>a. Personal Use.</b> Subject to your continued compliance with the
          terms of this License, Enchanted Studios grants you a worldwide,
          non-exclusive, non-transferable, non-sublicensable, royalty-free
          license to use, copy, modify, and display the Art for your Purchased
          NFTs, along with any Extensions that you choose to create or use,
          solely for the following purposes: (i) for your own personal,
          non-commercial use; (ii) as part of a marketplace that permits the
          purchase and sale of your NFTs, provided that the marketplace
          cryptographically verifies each NFT owner’s rights to display the Art
          for their Purchased NFTs to ensure that only the actual owner can
          display the Art; or (iii) as part of a third party website or
          application that permits the inclusion, involvement, or participation
          of your NFTs, provided that the website/application cryptographically
          verifies each NFT owner’s rights to display the Art for their
          Purchased NFTs to ensure that only the actual owner can display the
          Art, and provided that the Art is no longer visible once the owner of
          the Purchased NFT leaves the website/application. <br /><br />
          <b> b. Commercial Use.</b>
          Subject to your continued compliance with the terms of this License,
          Enchanted Studios grants you a limited, worldwide, non-exclusive,
          non-transferable license to use, copy, and display the Art for your
          Purchased NFTs for the purpose of commercializing your own merchandise
          that includes, contains, or consists of the Art for your Purchased
          NFTs (“Commercial Use”), up to One Million Dollars ($1,000,000) in
          gross revenue each year. For the sake of clarity, nothing in this
          Section 2.b will be deemed to restrict you from (i) owning or
          operating a marketplace that permits the use and sale of NFTs
          generally, provided that the marketplace cryptographically verifies
          each NFT owner’s rights to display the Art for their Purchased NFTs to
          ensure that only the actual owner can display the Art; (ii) owning or
          operating a third party website or application that permits the
          inclusion, involvement, or participation of NFTs generally, provided
          that the third party website or application cryptographically verifies
          each NFT owner’s rights to display the Art for their Purchased NFTs to
          ensure that only the actual owner can display the Art, and provided
          that the Art is no longer visible once the owner of the Purchased NFT
          leaves the website/application; or (iii) earning revenue from any of
          the foregoing, even where such revenue is in excess of $1,000,000 per
          year. <br /><br />
          <b
            >c. Enchanted Studios reserves all rights not explicitly granted
            herein.</b
          >
        </p>
      </div>
      <div class="mt-3">
        <h2 class="text-start">3. RESTRICTIONS</h2>
        <p>
          You agree that you may not, nor permit any third party to do or
          attempt to do any of the foregoing without Enchanted Studios express
          prior written consent in each case: (i) use the Art for your Purchased
          NFTs in connection with images, videos, or other forms of media that
          depict hatred, intolerance, violence, cruelty, or anything else that
          could reasonably be found to constitute hate speech or otherwise
          infringe upon the rights of others; (ii) attempt to trademark,
          copyright, or otherwise acquire additional intellectual property
          rights in or to the Art for your Purchased NFTs or those belonging to
          Enchanted Studios; and (iii) Use any Enchanted Studios owned content
          in any manner not expressly authorized herein or exercise any license
          rights herein in any manner that violates applicable law.<br /><br />
          Except for the license rights expressly granted in Section 2, above,
          you do not have and will not have any right, title or interest in or
          to any Enchanted Studios-Owned Content or IP. Without limiting the
          foregoing, the Enchanted Studios- Owned Content may include trade
          names, brands, logos, trademarks, character names, likenesses, images,
          lore and other IP (Embedded IP). Your use of such trademarks and
          Embedded IP is limited to use in connection with the exercise of your
          license rights under these Terms and subject to all limitations set
          forth herein and any other restrictions that Enchanted Studios may
          inform you of in the future. You may not use any such Embedded IP in
          connection with any business, product or service, or in any manner
          that may imply endorsement of any business, message, product or
          service, or that is likely to cause confusion or dilute, blur or
          tarnish such Embedded IP. You may not use or attempt to register any
          asset, including any domain names, social media accounts or related
          addresses, that contains or incorporates any artwork, other
          representation, name or mark that may be confusingly similar to such
          trademarks & Embedded IP. All use of such Embedded IP, including any
          goodwill generated by such use, will inure to the benefit of Enchanted
          Studios.<br /><br />
          To the extent that Enchanted Studios inform you of such additional
          restrictions, you will be responsible for complying with all such
          restrictions from the date that you receive the notice, and that
          failure to do so will be deemed a breach of this license. The
          restriction in Section 4 will survive the expiration or termination of
          this License.
        </p>
      </div>
      <div class="mt-3">
        <h2 class="text-start">4. TERMINATION</h2>
        <p>
          The license granted in Section 2 above applies only to the extent that
          you continue to Own the applicable Purchased NFT. This license follows
          with the NFT. It will automatically terminate upon a transfer of the
          NFT to a third party, and the license will be granted to such third
          party only upon their acceptance of these Terms of Sale and the other
          Applicable Terms.
        </p>
      </div>
      <div class="mt-3">
        <h2 class="text-start">5. IDEMNIFICATION</h2>
        <p>
          a. The digital collectible NFT from Enchanted Studios is intended for
          consumer enjoyment, use and consumption only. it is not a “security,”
          as defined under the securities act of 1933, as amended, the
          securities exchange act of 1934, as amended, the investment company
          act of 1940, as amended, or under the securities laws of any U.S.
          state.<br /><br />
          b. From time to time at their discretion, Enchanted Studios or third
          parties may make additional content, physical items or other benefits
          available to the holder of the applicable NFT (any of the foregoing,
          an “Additional Benefit”) including airdrops of new NFTs. Neither
          Enchanted Studios or any third party has any obligation to inform you
          of, or to provide you with, any Additional Benefit, and you should
          therefore not expect any Additional Benefit when acquiring an NFT. It
          will be your responsibility to keep yourself apprised of the
          availability of any Additional Benefit and to take the necessary steps
          to apply for or collect any Additional Benefit. The terms and
          conditions applicable to any Additional Benefit will be set forth in
          the information or materials provided with the Additional Benefit.
          Except to the extent set forth in such information or materials which
          shall supersede and govern over any conflicting or inconsistent
          provisions in these Terms, any digital works of authorship made
          available by Enchanted Studios as an Additional Benefit will be
          licensed on the same terms set forth herein for, and will otherwise be
          treated as, Enchanted Studios -Owned Content. Any physical item
          provided as an Additional Benefit is not part of the Enchanted Studios
          -Owned Content and, except as otherwise provided in any separate terms
          and conditions applicable to the Additional Benefit, you will not have
          any license rights under any intellectual property rights in or to any
          such physical item.<br /><br />
          c. You will indemnify and hold harmless Enchanted Studios and its
          Affiliates from and against any and all claims, demands, liabilities,
          damages, penalties, fines, taxes, costs and expenses (including
          without limitation reasonable attorneys’ fees and court costs) arising
          out of or in connection with 1) any breach of these Terms or
          unauthorized use of any Enchanted Studios-Owned Content 2) Your
          ownership or transfer of any NFT, or 3) Exercise of any license rights
          hereunder by you.<br /><br />
          d. The maximum aggregate liability of Enchanted Studios and its
          affiliates for all damages and causes of action, whether in contract,
          tort (including negligence) or otherwise, shall be $100.<br /><br />
        </p>
      </div>
      <div class="mt-3">
        <h2 class="text-start">6. DISPUTE RESOLUTION</h2>
        <p>
          If there is a disagreement between the NFY owner and the Licensee on
          the interpretation of this agreement or any aspect of the performance
          by either party of its obligations under this agreement,
          representatives of the parties will, within 10 days of receipt of a
          written request from either party to the other, meet in good faith and
          try to resolve the disagreement without recourse to legal proceedings.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "termofUse",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.vm--container {
  padding: 100px;
}
.terms {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 0.8rem;
  }
}
</style>
