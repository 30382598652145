<template>
  <div class="team-slider hideDesktop">
    <div class="team-txt">
      <p class="text-team">
        Our global team of NFT experts have been working for months to design a
        new kind of NFT project that is rooted in the celebration of diversity.
        <br />
        Between us we have:
      </p>

      <div class="text-wrapper">
        <textra
          :data="wordsServices"
          :infinite="true"
          :timer="4"
          filter="bottom-top"
          class="txt-animate"
          style="height: 140px"
        />
      </div>
    </div>

    <swiper
      class="swiper container mb-5 mt-5"
      :options="swiperOptions"
      style="margin-top: -20px"
    >
      <swiper-slide class="swiper-slide">
        <!-- <h2 class="m-0">Co-Founders</h2> -->
        <div class="categories mt-4 row">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-charmaine.png" />
              <h4 class="name special-text mt-2">CHARMAINE HUSSAIN</h4>
              <div class="text-center border-oro">
                <p>The Creatix</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">CHARMAINE HUSSAIN</h4>
                <h6 class="">FOUNDERS</h6>
                <p class="">
                  World-builder & lore-lover using stories to create an
                  empathetic, inclusive metaverse. She brings 25 yrs of
                  experience creating movements that matter, for Fortune 500
                  brands, top 5 retailers, and global nonprofits. She’s worked
                  with celebrities & been to the White House, but feels most at
                  home in her magical world called The Enchanted Valley.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/chussain" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/charmainehussain/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/chussain" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-himanshu.png" />
              <h4 class="name special-text mt-2">HIMANSHU ATRE</h4>
              <div class="text-center border-oro">
                <p>The Architect</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">HIMANSHU ATRE</h4>
                <h6 class="">FOUNDERS</h6>
                <p class="">
                  Product builder in the NFT and Payment technology space. When
                  not BUIDLing, he loves art and conversations about art,
                  travelling and taking pictures of nature, tasting local food,
                  coffee and wine wherever he goes.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/HimanshuAtre" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/himanshuatre/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/HimanshuAtre" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-violetta.png" />
              <h4 class="name mt-2">VIOLETTA MELNIKOVA</h4>
              <div class="text-center border-oro">
                <p>The Artist</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">VIOLETTA MELNIKOVA</h4>
                <h6 class="">CREATIVE TEAM</h6>
                <p class="">
                  Painter, illustrator, and visual storyteller. Passionate
                  traveler that digs into archetypes.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/Eyeabovesky" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/eye_above_sky/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/Eyeabovesky" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <div class="categories mt-4 row">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-ganesh.png" />
              <h4 class="name mt-2">GANESH KATAVE</h4>
              <div class="text-center border-oro">
                <p>The Runecaster</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">GANESH KATAVE</h4>
                <h6 class="">CREATIVE TEAM</h6>
                <p class="">
                  An Architect and Designer dabbling with CAD, symbols and
                  geometries. He revisits, revives and rethinks lost
                  mythological stories across different cultures. A huge
                  potterhead with a soft corner for all things noir and sublime.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/GANESHRK15" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/ganeshkatwe/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/GANESHRK15" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-adam.png" />
              <h4 class="name mt-2">ADAM GILAD</h4>
              <div class="text-center border-oro">
                <p>The Scribe</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">ADAM GILAD</h4>
                <h6 class="">CREATIVE TEAM</h6>
                <p class="">
                  Adam Gilad is an Emmy nominated Executive Producer and Writer,
                  with over 20 credits to his name. He is an inveterate
                  storyteller, world-builder, barolo drinker and chocolate
                  eater.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/AdamGilad" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.facebook.com/adam.gilad.7/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/AdamGilad" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/image/avatar-daniel.png" />
              <div class="creative"></div>
              <h4 class="name mt-2">DANIEL</h4>
              <div class="text-center border-oro">
                <p>The Tunesmith</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">DANIEL</h4>
                <h6 class="">CREATIVE TEAM</h6>
                <p class="">
                  Sound Engineer & Music Producer for 20+ years. Started at age
                  11 and has worked with MTV, VH1, CRIBS, Yo Momma, TrueLife,
                  Basketball Wives, Clyde Carson, E-40, LL-Cool J, and Paul
                  Wall.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/izitheartist" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/izitheartist/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/izitheartist" target="_blank"
                ><img src="../assets/img/twitter.svg" alt=""
                /></a> -->
          </div>
          <!-- IZI -->
          <!-- <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-izi.png" />
              <div class="creative"></div>
              <h4 class="name mt-2">IZI</h4>
              <div class="text-center border-oro">
                <p>The Metaverse Builder</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">IZI</h4>
                <h6 class="">METAVERSE TEAM</h6>
                <p class="">
                  Izitheartist is a self-taught 3d artist from Peru whose work is inspired by philosophy, cultural Heritage and Physical art. Currently working on building metaverse experiences.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/izitheartist" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a href="https://www.instagram.com/izitheartist/" target="_blanck">
                    <img src="../assets/image/ist.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div> -->
          <!-- IZI -->
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <!-- <h2 class="m-0">Community Guides</h2> -->
        <div class="categories row mt-4">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-jafeth.png" />
              <div class="community"></div>
              <h4 class="name mt-2">JAFETH PENTURY</h4>
              <div class="text-center border-oro">
                <p>The Strategist</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">JAFETH PENTURY</h4>
                <h6 class="">COMMUNITY GUIDES</h6>
                <p class="">
                  “The one with all the faces”, visionary artist, NFT strategist
                  and community builder. He believes you need a strong
                  foundation to build on, one can’t do it alone, and community
                  building became a lot easier through the medium of NFTs.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a
                    href="https://twitter.com/yourlittlefaces"
                    target="_blanck"
                  >
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/allyourlittle_nft/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/csuiluv" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-kyle.png" />
              <div class="community"></div>
              <h4 class="name mt-2">KYLE CORRIGAN</h4>
              <div class="text-center border-oro">
                <p>The Guard</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">KYLE CORRIGAN</h4>
                <h6 class="">COMMUNITY GUIDES</h6>
                <p class="">
                  Discord Moderator and Community Manager with Ambassadors Media
                  in the Web 3.0 and NFT space. Outside of space, he is a
                  varsity athlete at Bishop’s University playing Rugby.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/_KCorrigan_" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/_corrigan_/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/stargaz69927603" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-aivan.png" />
              <div class="community"></div>
              <h4 class="name mt-2">AIVAN BOLAMBAO</h4>
              <div class="text-center border-oro">
                <p>The Ambassador</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">AIVAN BOLAMBAO</h4>
                <h6 class="">COMMUNITY GUIDES</h6>
                <p class="">
                  You can find him building Web3 communities. Throughout all of
                  his different projects, Web3 has enabled his passion of being
                  a world builder. He looks to continue building revolutionary
                  projects at this intersection of art, culture and technology.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a
                    href="https://twitter.com/AmbassadorAivan"
                    target="_blanck"
                  >
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/ambassadoraivan/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/AmbassadorAivan" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <div class="categories mt-4 row">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-rohan.png" />
              <div class="community"></div>
              <h4 class="name mt-2">ROHAN GANAPATHY</h4>
              <div class="text-center border-oro">
                <p>The Gamemaster</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">ROHAN GANAPATHY</h4>
                <h6 class="">COMMUNITY GUIDES</h6>
                <p class="">
                  Surreal/conceptual photographer and multidisciplinary artist
                  who tells stories using whimsical realism. His style revolves
                  around creating escapes into surreal worlds filled with
                  symbolism that is meant to spread positivity. Also working as
                  a Senior QA Lead Engineer in the gaming industry for well over
                  a decade.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/RohanGanapathy" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/rohanganapathy/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/_KCorrigan_" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/image/avatar-vaddi.png" />
              <h4 class="name mt-2">HARSHITH</h4>
              <div class="text-center border-oro">
                <p>The Guide</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">HARSHITH</h4>
                <h6 class="">COMMUNITY GUIDES</h6>
                <p class="">
                  Community Builder with an entrepreneurial drive and constantly
                  seeking for truth by surrounding himself with people smarter
                  than him and questioning the fundamentals of existence.
                  Evolving to become the best version of himself. Here to stay.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/harshith_eth" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/harshith.eth/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/RohanGanapathy" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-suiluv.png" />
              <h4 class="name mt-2">SUI LUV</h4>
              <div class="text-center border-oro">
                <p>The Metavoxel Crafter</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">SUI LUV</h4>
                <h6 class="">METAVERSE TEAM</h6>
                <p class="">
                  Began her foray into digital art in Spring 2021, rendering
                  Asian calligraphy, and her Mochi character series. She
                  considers herself a lifelong learner. She is a voxel crafter
                  in the metaverse making wearables and voxel artworks. SuiLuv
                  spreads positive vibes and energy through each digital
                  creative piece.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/csuiluv" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a href="https://www.instagram.com/csuiluv/" target="_blanck">
                    <img src="../assets/image/ist.svg" />
                  </a>
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/yourlittlefaces" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <!-- <h2 class="m-0">Platform Development</h2> -->
        <div class="categories row mt-4">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-izi.png" />
              <div class="creative"></div>
              <h4 class="name mt-2">IZI</h4>
              <div class="text-center border-oro">
                <p>The Metaverse Builder</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">IZI</h4>
                <h6 class="">METAVERSE TEAM</h6>
                <p class="">
                  Izitheartist is a self-taught 3d artist from Peru whose work
                  is inspired by philosophy, cultural Heritage and Physical art.
                  Currently working on building metaverse experiences.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/izitheartist" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/izitheartist/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                </div>
              </div>
            </div>
            <!-- <a href="https://twitter.com/turinglabsorg" target="_blank"
                    ><img src="../assets/img/twitter.svg" alt=""
                    /></a> -->
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/image/avatar-dar.png" />
              <div class="creative"></div>
              <h4 class="name mt-2">DARKMYTHST</h4>
              <div class="text-center border-oro">
                <p>The Environment Builder</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">DARKMYTHST</h4>
                <h6 class="">METAVERSE TEAM</h6>
                <p class="">
                  Afrofuturist / Afrocosmic artist creating mythological art
                  fused with African cultural influence. His art tells stories
                  of positivity, optimism and a fantastical future of the
                  dreamer.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/Darkmythst1" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/darkmythst/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-seba.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">SEBASTIANO CATAUDO</h4>
              <div class="text-center border-oro">
                <p>Blockchain Developer</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">SEBASTIANO CATAUDO</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  During my decade as a Senior Full-Stack Developer, I founded
                  two companies Futuring and YOMI focused on Web3, Blockchain
                  and AI development. Sometimes words aren't needed, check out
                  my GitHub!
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <div class="categories mt-4 row">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-giorgia.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">GIORGIA GUIDETTI</h4>
              <div class="text-center border-oro">
                <p>Tech Project Manager</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">GIORGIA GUIDETTI</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  Driven by passion for communications, marketing and trends
                  affecting the fabric of society I turn challenges into
                  achievable goals. PM for YOMI, the perfect place to expand and
                  discover new digital worlds.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-livio.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">LIVIO LOMBARDO</h4>
              <div class="text-center border-oro">
                <p>Tech Project Manager</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">LIVIO LOMBARDO</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  Co-founder of YOMI where I work on finance and administration,
                  also I am a musician, eclectic and passionate I try to show
                  our clients the extraordinary potential of blockchain
                  technology.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-stefano.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">STEFANO RANIOLO</h4>
              <div class="text-center border-oro">
                <p>Platform Development</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">STEFANO RANIOLO</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  Passionate about technology in all its forms and a nerd since
                  birth, I became a front end developer after a path as a
                  graphic designer. Today web3 development is my goal to
                  achieve.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-slide">
        <div class="categories mt-4 row">
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/image/gn_ev.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">GIUSEPPE NAPOLI</h4>
              <div class="text-center border-oro">
                <p>Platform Development</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">GIUSEPPE NAPOLI</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  Thanks to development I managed to turn my passion into a job.
                  Today I'm a front-end developer who aims to expand my
                  knowledge and get in the game.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="member col-10 offset-1 col-md-12">
            <div class="image-team">
              <img src="../assets/img/avatar-louba.png" />
              <div class="devs"></div>
              <h4 class="name mt-2">LOUBA</h4>
              <div class="text-center border-oro">
                <p>Platform Development</p>
              </div>
              <div class="box-nascosto">
                <h4 class="">LOUBA</h4>
                <h6 class="">PLATFORM DEVELOPMENT</h6>
                <p class="">
                  Frontend developer and web designer, keen on anything
                  visual-related, from typography to shaders.
                </p>
                <div class="social-nasc d-flex justify-start">
                  <a href="https://twitter.com/YOMI_WEB3" target="_blanck">
                    <img src="../assets/image/tw.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/yomi_web3/"
                    target="_blanck"
                  >
                    <img src="../assets/image/ist.svg" />
                  </a>
                  <!-- <a href="">
                    <img src="../assets/image/nav.svg" />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <div
        style="outline-style: none"
        class="swiper-button-prev"
        slot="button-prev"
      >
        <img class="freccia-sx" src="../assets/image/Group 143.svg" alt="" />
      </div>
      <div
        style="outline-style: none"
        class="swiper-button-next"
        slot="button-next"
      >
        <img class="freccia-dx" src="../assets/image/Group 142.svg" alt="" />
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

// install Swiper modules

export default {
  name: "teamMobile",



  data() {
    return {
      swiperOptions: {},
      wordsServices: [
        "Over 3000 hours of NFT onboarding experience",
        "Access to over 5,000 NFT creators",
        "Written a dozen smart contracts",
        "Implemented 22 Web 3.0 projects",
        "Exhibited artwork in New York, Miami, Beijing, Hong Kong, India and the Metaverse",
        "Written for Gargoyles, Pink Panther, The Incredible X-Men, Iron Man, The Mask, Extreme Ghostbusters, Mummies Alive",
        "Sold art on platforms like Known Origin, Foundation, Opensea, Rarible, Kalamint, HEN, WazirX, and Async Art",
        "Worked on shows seen on Disney, Fox, CBS, TNT, NBC, ABC, Lifetime, USA Networks etc.",
        "Collected over 600 NFTs, with emphasis on art and projects from women, BIPOC, and LGBTQIA+ artists ",
      ],
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped></style>
