<template>
  <footer class="p-0">
    <div class="box-valley">
      <div class="txt-world text-valley">
        <textra :data="words" :infinite="true" :timer="4" filter="bottom-top" />
      </div>
      <!-- <div style="top: 0px" class="top-sfumatura">
        <img class="top-sfumat" src="../assets/image/sfumatura.svg" alt="" />
      </div> -->
      <!-- <img src="../assets/image/valle.webp" alt="" /> -->
        <div class="valley-bg">
          <div class="gradient-topdown"></div>
          <video preload="auto" autoplay muted defaultMuted playsinline loop>
            <source src="../assets/img/EV-video-animated.mp4" type="video/mp4" />
            <source
              src="../assets/img/EV-video-animated.webm"
              type="video/webm"
            />
            <source src="../assets/img/EV-video-animated.ogg" type="video/webm" />
          </video>
          <div class="gradient-downtop"></div>
        </div>
        <!-- s -->
      <div class="">
        <!-- <img class="bot-sfumat" src="../assets/image/sfumatura-bt.svg" alt="" /> -->
        <div class="container-fluid footer-fluid">
          <div class="row">
            <div class="col-12 mb-2">
              <img class="w-100 linea" src="../assets/image/separatore.svg" alt="" />
            </div>
          </div>
          <div class="row justify-content-end text-center">
            <div class="col-12 col-lg-3 col-md-4 order-3 order-lg-1"></div>
            <div class="col-12 col-lg-6 col-md-4 order-2 order-lg-2">
              <p class="text-center testo-centrale">
                © All Works Are Copyright Of Enchanted Studios LLC.

                <br /><span style="font-size: 0.7rem"
                  >Developed with ❤️ by
                  <a
                    href="https://yomi.digital"
                    style="
                      color: #fae090;
                      text-decoration: none;
                      font-weight: 900;
                    "
                    >YOMI</a
                  ></span
                >
              </p>
            </div>
            <div class="col-12 col-lg-3 col-md-4 order-1 order-lg-3">
              <div id="contact" class="social">
                <a href="https://discord.com/invite/mP5kJTaD7H" target="blank_"
                  ><img src="../assets/image/disco.svg" alt="discord link"
                /></a>
                <a
                  href="https://opensea.io/collection/the-enchanteds"
                  target="blank_"
                  ><img src="../assets/image/navi.svg" alt="opensea link"
                /></a>
                <a href="https://twitter.com/EnchantedNFTs" target="blank_">
                  <img src="../assets/image/twit.svg" alt="twitter link" />
                </a>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "newFooter",
  data() {
    return {
      active: false,
      show: false,
      words: [
        "Imagine a world where magic is real",
        "Explore a world where folktales come alive",
        "Create in a world where imagination is currency",
        "Own a piece of a world where differences are celebrated",
        "Become a part of a world where epic adventures await",
        "Make your home in a world where you belong",
      ],
    };
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      setTimeout(() => (this.show = !this.show), 10);
    },
  },
};
</script>

<style></style>
