<template>
  <div class="container-fluid">
    <header class="navbar new-header row">
      <div class="d-flex ps-2 pe-5 navig-fis">
        <div class="new-logo-images col-md-4">
          <img
            class="new-home-logo"
            src="../assets/img/logo.svg"
            alt="enchanted valley logo"
            draggable="false"
            width="111px"
          />
          <img
            class="new-valley-text-logo"
            src="../assets/img/gold-valley.svg"
            alt="enchanted valley title"
            draggable="false"
          />
        </div>

        <!-- <a
          href="#mint"
          style="text-decoration: none; margin-right: 20px"
          ><div class="mint-btn hideMobile">MINT NOW</div></a
        > -->
        <!-- TODO: show when the sale is enabled -->
        <!-- <a
          @click="showModalSale()"
          style="text-decoration: none; margin-right: 20px"
          ><div class="mint-btn hideMobile">MINT NOW</div></a
        > -->
        <div
          class="d-flex justify-content-end align-items-center col-md-8 menu-block"
        >
          <ul class="nav-menu">
            <li>
              <loreModel />
            </li>
            <li>
              <img class="me-2 rombo" src="../assets/image/rombo.svg" alt="" />
              <a href="#team">Team</a>
              <img class="ms-1 rombo" src="../assets/image/rombo.svg" alt="" />
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
          </ul>
          <ul class="social-hd ms-4 me-4">
            <li>
              <a href="https://twitter.com/EnchantedNFTs" target="_black">
                <img class="img-social" src="../assets/image/tw.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="https://discord.com/invite/mP5kJTaD7H" target="_black">
                <img
                  class="img-social"
                  src="../assets/image/icon-disc.svg"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="https://opensea.io/collection/the-enchanteds"
                target="_black"
              >
                <img class="img-social" src="../assets/image/nav.svg" alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/enchantedvalleynft/"
                target="_black"
              >
                <img class="img-social" src="../assets/image/ist.svg" alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://etherscan.io/address/0xf4bF7C9b229F1B8728001349918196B344ace80f"
                target="_black"
              >
                <img
                  class="img-social"
                  src="../assets/image/Group (7).svg"
                  alt=""
                />
              </a>
            </li>
          </ul>
          <!-- TODO: show when the sale is enabled -->
          <!-- <a
            @click="showModalSale()"
            style="text-decoration: none; margin-right: 20px"
            ><div class="new-mint-btn hideMobile">Mint</div>
          </a> -->
          <!-- <a
          
            style="text-decoration: none; margin-right: 20px"
            ><div class="new-mint-btn-tp hideMobile">Coming Soon</div>
            </a> -->
        </div>
        <secondMenu />
      </div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <img
              class="new-linee"
              src="../assets/image/separatore.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </header>
    <!--  <img class="new-linee" src="../assets/image/linea-rb.svg" alt="" /> -->
    <modal name="mintingfae" class="structure">
      <MintingFae />
    </modal>
  </div>
</template>

<script>
import MintingFae from "@/components/MintingFae.vue";
import secondMenu from "@/components/secondMenu.vue";
import loreModel from "@/components/loreModel.vue";
export default {
  name: "newHeader",
  components: {
    MintingFae,
    secondMenu,
    loreModel,
  },

  methods: {
    showModalSale() {
      this.$modal.show("mintingfae");
    },
  },
};
</script>
