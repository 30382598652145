<template>
  <div>
    <!-- <Loader :loading="pageLoading" /> -->
    <!-- <div v-if="(loading = !pageLoading)" class="home"> -->
    <div class="home">
      <newHeader />
      <main id="page-wrap">
        <!--=============>>>>>>>>FIRST PART NEW-SITE<<<<<<<===========-->
        <div class="container sec-head">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 order-2 order-md-1">
              <div class="mb-5 p-bord">
                <h1>
                  Welcome to The <br />
                  Enchanted Valley
                </h1>
                <ul class="ps-3">
                  <li class="mb-2">
                    An inclusive fantasy world populated by The Enchanteds.
                  </li>
                  <li class="mb-2">
                    Avatar art collectibles randomly generated from a record
                    breaking ~1500 hand drawn traits, spread across 5 equal
                    drops of 2200 NFTS.
                  </li>
                  <li class="mb-2">Diverse, global, fully doxxed team.</li>
                  <li class="mb-2">
                    Ethereum blockchain, ERC-721 tokens, hosted on IPFS.
                  </li>
                </ul>
              </div>
              <!-- TODO: show when the sale is enabled -->
              <!-- <div class="hideMobile">
                <div class="btn-head">
                  <button @click="showModalSale()">
                    The Enchanteds Are Minting Now
                  </button>
                </div>
              </div> -->
            </div>
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-5 offset-lg-1 order-1 order-md-2 center-head-img"
            >
              <div class="bk-head-img">
                <img
                  class="img-1"
                  src="@/assets/img/Intermediate_EV-24.svg"
                  alt=""
                />
                <img class="img-2" src="@/assets/img/placeholder.gif" alt="" />
              </div>
            </div>
            <!-- TODO: show when the sale is enabled -->
            <!-- <div class="mt-4 hideDesktop">
              <div class="btn-head-mob">
                <button @click="showModalSale()">
                  FAE - Order of Land Minting Now
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <!--=============>>>>>>>>FIRST PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FIRST PART OLD-SITE<<<<<<<===========-->

        <!-- <div class="valley-bg">
          <div class="gradient-topdown"></div>
          <video preload="auto" autoplay muted defaultMuted playsinline loop>
            <source src="../assets/img/EV-video-animated.mp4" type="video/mp4" />
            <source
              src="../assets/img/EV-video-animated.webm"
              type="video/webm"
            />
            <source src="../assets/img/EV-video-animated.ogg" type="video/webm" />
          </video>
          <div class="gradient-downtop"></div>
        </div> -->

        <!-- <div class="container section-head">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-6 col-lg-7">
              <div class="mb-4">
                <h1>The Enchanteds</h1>
                <p>
                  Digital avatar art collectibles randomly generated from a record
                  breaking ~1500 traits, across 5 drops of 2200 NFTS each.
                  <br /><br />
                  The Enchanteds represent magical beings of Enchanted Valley on the
                  Ethereum blockchain as ERC-721 tokens, hosted on IPFS.
                </p>
              </div>
              <div class="hideMobile">
                <h3>
                  Drop #1 - The Fae <br />
                  Public Mint : 7th March
                </h3>
                <p>
                  The Fae | Tiny, elf-like creatures belonging to The Order Of The
                  Land | Industrious nature’s artisans | Live in meadows & forests |
                  Sport antlers, colorful blooms & mushroom sprouts | Usually
                  cheerful & friendly | When threatened they can quake the ground &
                  march trees into battle.
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 center-head-img">
              <div class="bk-head-img">
                <img class="img-1" src="@/assets/img/Intermediate_EV-24.svg" alt="">
                <img class="img-2" src="@/assets/img/placeholder.gif" alt="">
              </div>
            </div>
            <div class="mt-4 hideDesktop">
                <h3>
                  Drop #1 - The Fae <br />
                  Public Mint : 7th Feb
                </h3>
                <p>
                  The Fae | Tiny, elf-like creatures belonging to The Order Of The
                  Land | Industrious nature’s artisans | Live in meadows & forests |
                  Sport antlers, colorful blooms & mushroom sprouts | Usually
                  cheerful & friendly | When threatened they can quake the ground &
                  march trees into battle.
                </p>
              </div>
          </div>
        </div>
        <div class="container txt-world">
          <textra :data="words" :infinite="true" :timer="4" filter="bottom-top" />
        </div> -->

        <!--=============>>>>>>>>FIRST PART OLD-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>SECOND PART NEW-SITE<<<<<<<===========-->
        <div class="rari">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 bk-banner-lore">
                <p>The Enchanteds</p>
              </div>
            </div>
          </div>
          <div class="container mt-5 mb-3">
            <div class="row hideMobileF">
              <!-- fata -->
              <div class="box-fata">
                <div class="bk-name">
                  <p>Fae - Land Order</p>
                </div>
                <div class="img-fate">
                  <img src="../assets/image/land.webp" alt="" />
                </div>
                <!-- TODO: show when the sale is enabled -->
                <!-- <div
                  class="bk-pieno"
                  @click="showModalSale()"
                  style="cursor: pointer"
                >
                  <p>Mint A Fae</p>
                </div> -->
                <div class="bk-pieno" style="cursor: pointer">
                  <p>Phase 1 Sold Out</p>
                </div>
                <!-- <p class="text-center mt-2">{{ tribes[0] }}/2200</p> -->
              </div>
              <!-- fata -->
              <!-- fata -->
              <div class="box-fata">
                <div class="bk-name">
                  <p>Jinn - Fire Order</p>
                </div>
                <div class="img-fate">
                  <img src="../assets/image/fire.webp" alt="" />
                </div>
                <div class="bk-vuoto">
                  <p>Coming Soon</p>
                </div>
              </div>
              <!-- fata -->
              <!-- fata -->
              <div class="box-fata">
                <div class="bk-name">
                  <p>Nymph - Water Order</p>
                </div>
                <div class="img-fate">
                  <img src="../assets/image/water.webp" alt="" />
                </div>
                <div class="bk-vuoto">
                  <p>Coming Soon</p>
                </div>
              </div>
              <!-- fata -->
              <!-- fata -->
              <div class="box-fata">
                <div class="bk-name">
                  <p>Ephemera - Air Order</p>
                </div>
                <div class="img-fate">
                  <img src="../assets/image/air.webp" alt="" />
                </div>
                <div class="bk-vuoto">
                  <p>Coming Soon</p>
                </div>
              </div>
              <!-- fata -->
              <!-- fata -->
              <div class="box-fata">
                <div class="bk-name">
                  <p>Mystery Order</p>
                </div>
                <div class="img-fate">
                  <img src="../assets/image/cosmos.webp" alt="" />
                </div>
                <div class="bk-vuoto">
                  <p>Coming Soon</p>
                </div>
              </div>
              <!-- fata -->
            </div>
            <div class="row hideDesktop">
              <slideCooming />
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div id="rarities" class="col-12 bk-banner-lore">
                <p>Rarities and Traits</p>
              </div>
            </div>
          </div>
        </div>
        <!--=============>>>>>>>>SECOND PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>SECOND PART OLD-SITE<<<<<<<===========-->

        <!-- <div class="container text-center mt-5 mb-5">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../assets/img/Title-right.svg"
              alt=""
              width="100px"
              style="transform: scaleX(-1)"
            />
            <h1 class="right-aligned-titles mx-4 my-0">
              <span>Welcome to</span> Enchanted Valley!
            </h1>
            <img src="../assets/img/Title-right.svg" alt="" width="100px" />
          </div>
        </div> -->

        <!-- <section id="valley" class="main-section">
          <div class="container">
            <div class="row align-items-start">
              <div class="col-12 col-md-12 col-lg-6">
                <vue-aos animation-class="animate__animated animate__slideInLeft">
                  <img src="../assets/img/img-val-1.png" width="100%" />
                </vue-aos>
              </div>
              <div class="col-12 col-md-12 col-lg-6">
                <vue-aos
                  animation-class="animate__animated animate__slideInRight"
                >
                  <p class="frame1 text-center text-lg-end">
                    A mysterious world hidden at the edge of time and space.
                    Verdant forests hum with exuberant wildflowers. Glistening
                    waterfalls undulate along cliff-faces before tumbling into
                    watery pools. Glowing tongues of flame carve rivulets through
                    ancient rock, leading to treasure filled caves. Clouds of
                    steam rise towards airborne islands where wispy spirits
                    conjure soulful music. Every surface of Enchanted Valley is
                    alive, including its sky!
                    <br />
                    <br />
                    It is a harmonious world, holding secrets that could change
                    the very course of our existence. Shadows gather. Signs from
                    an ancient prophecy are starting to manifest. All that stands
                    between life and death is the ability to unravel the mysteries
                    hidden in the enigmatic Artifact at the very heart of the
                    Valley.
                  </p>
                </vue-aos>
              </div>
            </div>
          </div>
        </section> -->

        <!--=============>>>>>>>>SECOND PART OLD-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>THIRD PART NEW-SITE<<<<<<<===========-->

        <div class="container third-sec">
          <div class="row p-bord">
            <div class="col-12 text-center">
              <p>
                At Enchanted Valley we believe representation matters. It took
                us 6 months to create a collection with a record breaking ~1500
                lovingly hand drawn traits. We did not compromise on either our
                mission, or the quality of our artwork. We hope you enjoy the
                variety, find an avatar that feels both unique & special, and
                know that Enchanted Valley is a place where difference is
                celebrated.
              </p>
            </div>
          </div>
          <div class="row p-bord mt-5 mb-5">
            <div class="col-12 col-md-6 col-lg-5">
              <div class="left-list">
                <div class="cont-gold"><p>enchanted traits</p></div>
                <div class="cont"><p>the fae order</p></div>
                <!-- <div class="hideDesktop">
                  <div
                    class="cont-gold d-flex justify-content-between text-uppercase"
                  >
                    <p>enchanteds</p>
                    <p>1100</p>
                  </div>
                  <div
                    class="cont d-flex justify-content-between text-uppercase"
                  >
                    <p>orders</p>
                    <p>05</p>
                  </div>
                </div> -->
                <div class="mt-3 mb-2">
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Clothing
                    </p>
                    <p>83</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Hair
                    </p>
                    <p>77</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Flowers
                    </p>
                    <p>23</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Background
                    </p>
                    <p>19</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Antlers/Horns
                    </p>
                    <p>18</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Lips
                    </p>
                    <p>17</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Expressions
                    </p>
                    <p>16</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Face
                    </p>
                    <p>14</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Accessories
                    </p>
                    <p>13</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Ear
                    </p>
                    <p>10</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Eyes
                    </p>
                    <p>6</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" /> Skin
                    </p>
                    <p>3</p>
                  </div>
                  <div class="d-flex justify-content-between list-rar">
                    <p>
                      <img src="../assets/image/style-list.svg" alt="" />
                      Enchanted Creatures Companions
                    </p>
                    <p>2</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-7 hideMobileF center-head-img">
              <div class="row">
                <div class="col-6 col-lg-5 offset-0 offset-lg-1 box-rarity">
                  <div class="box-rar">
                    <img src="../assets/image/prima.png" alt="" />
                    <div class="descr-fat">
                      <p>one fae-jinn</p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-5 offset-0 offset-lg-1 box-rarity">
                  <div class="box-rar">
                    <img src="../assets/image/seconda.png" alt="" />
                    <div class="descr-fat">
                      <p>ENCHANTED LOCKETS</p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-5 offset-0 offset-lg-1 box-rarity">
                  <div class="box-rar">
                    <img src="../assets/image/terza.png" alt="" />
                    <div class="descr-fat">
                      <p>FAE COMPANIONS</p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-lg-5 offset-0 offset-lg-1 box-rarity">
                  <div class="box-rar">
                    <img src="../assets/image/quarta.png" alt="" />
                    <div class="descr-fat">
                      <p>RARE FAE TRAITS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 hideDesktop">
              <slideRare />
            </div>
          </div>
          <div class="row hideMobile">
            <div class="col-12 row-third-section">
              <img src="../assets/image/Group (4).svg" alt="" />
              <p>
                Don’t forget to look out for a special one-of-one Fae created by
                SoulCurryArt
              </p>
              <img src="../assets/image/Group (5).svg" alt="" />
            </div>
          </div>
        </div>
        <!--=============>>>>>>>>THIRD PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>THIRD PART OLD-SITE<<<<<<<===========-->

        <!-- <div class="container text-center mt-5 mb-5">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../assets/img/Title-right.svg"
              alt=""
              width="100px"
              style="transform: scaleX(-1)"
            />
            <h1 class="right-aligned-titles mx-4 my-0">Behold The Artifact</h1>
            <img src="../assets/img/Title-right.svg" alt="" width="100px" />
          </div>
        </div>

        <Artifact id="artifact" />

        <div id="team">
          <div class="container text-center mt-5 mb-5">
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="../assets/img/Title-right.svg"
                alt=""
                width="100px"
                style="transform: scaleX(-1)"
              />
              <h1 class="right-aligned-titles mx-4 my-0">The Team</h1>
              <img src="../assets/img/Title-right.svg" alt="" width="100px" />
            </div>
          </div>
        </div> -->
        <!--=============>>>>>>>>THIRD PART OLD-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FOURTH PART NEW-SITE<<<<<<<===========-->
        <div class="container-fluid sfumatura-top">
          <div class="row">
            <div id="roadmap" class="col-12 bk-banner-lore">
              <p>Enchanted Roadmap</p>
            </div>
          </div>
        </div>
        <!-- <div class="bk-ev container-fluid">
          <div style="top: 0px" class="top-sfumatura">
            <img src="../assets/image/sfumatura.svg" alt="" />
          </div>
          <div class="bottom-sfumatura">
            <img src="../assets/image/sfumatura-bt.svg" alt="" />
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <newRoadmap />
              </div>
            </div>
          </div>
        </div> -->
        <div class="bk-ev position-relative">
          <div style="top: -20px" class="position-absolute w-100">
            <img
              class="image-sfum"
              src="../assets/image/sfumatura-bt.svg"
              alt=""
            />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <newRoadmap />
              </div>
            </div>
          </div>
        </div>

        <!--=============>>>>>>>>FOURTH PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FOURTH PART OLD-SITE<<<<<<<===========-->
        <!-- <Team /> -->
        <!--=============>>>>>>>>FOURTH PART OLD-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FIFTH PART NEW-SITE<<<<<<<===========-->
        <div class="container-fluid sfumatura-top">
          <div class="row">
            <div class="col-12 bk-banner-lore">
              <p>FAQ</p>
            </div>
          </div>
        </div>
        <div class="container mb-5 questions">
          <div class="row">
            <Faq v-for="(item, index) in faqs" :key="index" :user="item">
              <div class="col-12">
                {{ item.answer }}
              </div>
            </Faq>
          </div>
          <div class="row">
            <div class="col-12 border-oro">
              <div class="text-center">
                <p v-if="faqs.length == 3" @click="loadMore()">Know More</p>
                <p v-if="faqs.length > 3" @click="loadLess()">Know Less</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 p-0">
              <img class="w-100" src="../assets/image/separatore.svg" alt="" />
            </div>
          </div>
        </div>

        <!--=============>>>>>>>>/FIFTH PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FIFTH PART OLD-SITE<<<<<<<===========-->
        <!-- <div id="roadmap">
          <div class="container text-center mt-5 mb-5">
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="../assets/img/Title-right.svg"
                alt=""
                width="100px"
                style="transform: scaleX(-1)"
              />
              <h1 class="right-aligned-titles mx-4 my-0">The Roadmap</h1>
              <img src="../assets/img/Title-right.svg" alt="" width="100px" />
            </div>
          </div>
        </div>
        <Roadmap /> -->
        <!--=============>>>>>>>>FIFTH PART OLD-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>FIFTH.1 PART NEW-SITE<<<<<<<===========-->
        <newArtifact />
        <!--=============>>>>>>>>FIFTH.1 PART NEW-SITE<<<<<<<===========-->

        <!--=============>>>>>>>>SIXTH PART NEW-SITE<<<<<<<===========-->
        <div id="team" class="container-fluid sfumatura-top">
          <div class="row">
            <div class="col-12 bk-banner-lore">
              <p>Meet our Team</p>
            </div>
          </div>
        </div>
        <div class="hideMobile">
          <newTeam />
        </div>
        <div class="hideDesktop">
          <teamMobile />
        </div>
        <!--=============>>>>>>>>SIXTH PART NEW-SITE<<<<<<<===========-->
        <Alliens />
        <newFooter />

        <!-- <Footer /> -->
        <div class="goTop" @click="scrollToTop()" id="myBtn">
          <i class="fas fa-chevron-up"></i>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import newHeader from "@/components/newHeader.vue";
/* import Loader from "@/components/Loader.vue"; */
/* import Artifact from "@/components/Artifact.vue"; */
import newArtifact from "@/components/newArtifact.vue";
/* import Footer from "@/components/Footer.vue"; */
import newFooter from "@/components/newFooter.vue";
/* import Team from "@/components/Team.vue"; */
import newTeam from "@/components/newTeam.vue";
import teamMobile from "@/components/teamMobile.vue";
/* import Roadmap from "@/components/Roadmap.vue"; */
import newRoadmap from "@/components/newRoadmap.vue";
import slideCooming from "@/components/slideCooming.vue";
import slideRare from "@/components/slideRare.vue";
import Faq from "@/components/Faq.vue";
import Alliens from "@/components/Alliens.vue";
const axios = require("axios");
export default {
  name: "Home",

  data() {
    return {
      pageLoading: true,
      dynamicList: [],
      length: 3,
      words: [
        "Imagine a world where magic is real",
        "Explore a world where folktales come alive",
        "Create in a world where imagination is currency",
        "Own a piece of a world where differences are celebrated",
        "Become a part of a world where epic adventures await",
        "Make your home in a world where you belong",
      ],
      tribes: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    };
  },

  components: {
    /* Loader, */
    newHeader,
    /* Artifact, */
    newArtifact,
    /* Team, */
    /* Roadmap, */
    newTeam,
    teamMobile,
    newRoadmap,
    slideCooming,
    slideRare,
    Faq,
    Alliens,
    /* Footer, */
    newFooter,
  },

  async mounted() {
    const app = this;
    let minted = await axios.get(process.env.VUE_APP_API_ENDPOINT + "/info");
    app.tribes = minted.data;
    console.log("Received minted data:", app.tribes);
    if (window != undefined && null) {
      setTimeout(() => {
        document.querySelector(".main-section").style.display = "none";
      }, 0);
      setTimeout(() => {
        document.querySelector(".main-section").style.display = "block";
      }, 4000);
    }
    setTimeout(() => {
      document.querySelector("body").style.overflowY = "scroll";
    }, 4000);
    setTimeout(() => {
      this.pageLoading = false;
    }, 4500);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    prepareDynamicList() {
      let list = [
        {
          question: "Who are The Enchanteds and how will they be released?",
          answer:
            "•  The Enchanteds are magical beings of Enchanted Valley belonging to 5 Orders. The Collection will be released one Order of 2200 NFTs at a time. The first Order to drop is the Fae, followed by the other 4 orders- Jinns, Nymphs, Ephemera, and the one final mystery Order.",
        },
        {
          question:
            "What is the price of an Enchanteds token and where can I mint them?",
          answer:
            "• The Enchanteds will be available to mint at the price of 0.08 ETH on our website https://www.enchantedvalleynft.com/.",
        },
        {
          question: "What is the Drop schedule for the Order of Fae?",
          answer:
            "• Public Minting will open on March 7th.     • Fae PreMint Sale on March 6th.     • Artifact Holders can start redeeming their Artifacts for Fae exclusively before Premint Sale starts on March 4th.",
        },
        {
          question:
            "I am an Artifact Holder, how can I ensure I get my Free Enchanteds?",
          answer:
            "• We will always put Artifact holders first. That is why we are giving Artifact holders 2 days to claim their free Fae even before Premint List opens. Although you can always claim your Fae later till they are sold out, we highly recommend claiming your Fae during this dedicated window to avoid a scenario where the collection is sold out later. We CANNOT RESERVE any tokens once Premint sale starts, so please use the Artifact redemption window to make your claim.",
        },
        {
          question: "Can I use my Artifact to claim a free NFT from any Order?",
          answer:
            "• Yes, your Artifact can be used to claim a free NFT from any Order. We will have Artifact redemption windows for every Order before the Premint sale starts.",
        },
        {
          question:
            "I want to buy an Artifact from the secondary market, how can I check if that token is already claimed for free Enchanteds NFT?",
          answer:
            "• Each Artifact token can be used once to claim a free Enchanteds NFT. You should check if an Artifact is already redeemed for a free Enchanted by entering its token ID in the Artifact section of our website before purchasing it from any secondary market.",
        },
      ];
      list.forEach((element) => {
        this.dynamicList.push({ ...element, visible: false });
      });
    },

    loadMore() {
      if (this.length > this.dynamicList.length) return;
      this.length = this.length + 6;
    },
    loadLess() {
      if (this.length < this.dynamicList.length) return;
      this.length = this.length - 6;
    },
    showModalSale() {
      this.$modal.show("mintingfae");
    },
  },
  async created() {
    await this.prepareDynamicList();
  },
  computed: {
    faqs() {
      return this.dynamicList.slice(0, this.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-head button {
  &:hover {
    color: #070032;
    background-color: #fae090;
  }
}
</style>
