<template>
  <div>
    <p style="cursor: pointer" @click="toggleModal">Lore</p>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <!-- contenuto modal -->
          <div class="modal-body p-0">
            <div class="privacy" style="border-radius: 7px">
              <button
                type="button"
                class="btn position-absolute"
                style="padding: 0.01rem 0.55rem; right: 10px; z-index: 9999"
                data-dismiss="modal"
                aria-label="Close"
                @click="toggleModal"
              >
                <span
                  style="font-size: 2rem; color: #fae090"
                  aria-hidden="true"
                  >&times;</span
                >
              </button>
              <div style="background-color: #050123">
                <div class="position-relative">
                  <img class="w-100 valle-lore" src="../assets/image/valle.webp" alt="" />
                  <div style="bottom: 0px" class="position-absolute w-100">
                    <img
                      class="w-100"
                      src="../assets/image/sfumatura-bt.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="container-fluid p-0 hideMobile">
                  <div class="row p-0">
                    <div class="col-12 p-0 bk-banner-lore bk-banner-position">
                      <p>The Lore</p>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row pt-5 hideDesktop">
                    <div class="col-12 p-0 bk-banner-lore bk-banner-position">
                      <p>The Lore</p>
                    </div>
                  </div>
                  <div class="row">
                    <p class="description-lore">
                      <img
                        style="position: relative; bottom: 15px"
                        src="../assets/image/Frame (2).svg"
                        alt=""
                      />
                      Welcome to Enchanted Valley. A mysterious world hidden at
                      the edge of time and space. Verdant forests hum with
                      exuberant wildflowers. Glistening waterfalls undulate
                      along cliff-faces before tumbling into watery pools.
                      Glowing tongues of flame carve rivulets through ancient
                      rock, leading to treasure filled caves. Clouds of steam
                      rise towards airborne islands where wispy spirits conjure
                      soulful music. Every surface of Enchanted Valley is alive,
                      including its sky! It is a harmonious world, holding
                      secrets that could change the very course of our
                      existence. Shadows gather. Signs from an ancient prophecy
                      are starting to manifest. All that stands between life and
                      death is the ability to unravel the mysteries hidden in
                      the enigmatic Artifact at the very heart of the Valley.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /contenuto modal -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loreModal",
  data() {
    return {
      active: false,
      show: false,
    };
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
        window.scrollY
      setTimeout(() => (this.show = !this.show), 10);
       document.body.classList.toggle('overflow-hidden')
    },

  },
};
</script>

<style>
.modal-body{
}
</style>
