<template>
  <div class="modal-mint">
    <div class="container">
      <div class="text-center">
        <!--<div v-if="!account && !metamaskInstalled && !isMobile">
          <h3 class="mb-5">
            You have to install Metamask extension to mint NFTs!
          </h3>
        </div>
        <div v-if="!account && !metamaskInstalled && isMobile">
          <h3 class="mb-5">
            You have to connect with Metamask Browser to mint NFTs!
          </h3>
        </div>-->
        <div v-if="!account">
          <h3 class="mb-5">Please Connect your Wallet</h3>
          <div class="d-flex justify-content-center">
            <div class="btn-web3">
              <div class="mint-btn" @click="connect()">
                <p>Connect Wallet</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CHECK CONTROLS -->
        <div
          class="mb-5"
          v-if="account && !isSaleOpen && !whitelistActive && !loadingState"
        >
          <h2 class="m-0">Welcome</h2>
          <div class="gold-line"></div>
          <div
            class="d-flex justify-content-center align-items-center text-center"
          >
            <img class="me-3" src="../assets/image/flower.svg" alt="" />
            <h3 class="m-0">
              <em>Our Public Sale Starts on March 7th at 7 AM PST.</em>
            </h3>
            <img class="flipped ms-3" src="../assets/image/flower.svg" alt="" />
          </div>
        </div>

        <div class="mb-5" v-if="whitelistActive && loadingWhitelistAddress">
          <h2 style="margin-top: 100px">Checking Pre-mint list</h2>
          <i class="fas fa-spinner fa-pulse"></i>
        </div>

        <div
          class="mb-5"
          v-if="
            whitelistActive &&
            !loadingWhitelistAddress &&
            !checkIfWhiteListed &&
            !artifactOwned &&
            !isSaleOpen
          "
        >
          <h2 style="margin-top: 90px">
            Looks like you don't own an Artifact or are not on the Enchanted
            Premint List. Please come back on 7th March when we start Public
            Mint of the Enchanted Fae
          </h2>
        </div>

        <!-- END CONTROLS -->

        <!-- WHITELIST SALE || PUBLIC SALE -->

        <!-- WHITELIST CONTROL: account && whitelistActive && checkIfWhiteListed && !loadingWhitelistAddress && !loadingState  -->
        <!-- OPEN SALE CONTROL: !whitelistActive && account && isSaleOpen -->

        <div v-if="account && (isSaleOpen || whitelistActive)">
          <div>
            <div>
              <h2>
                CONGRATULATIONS !! YOU ARE ONE STEP AWAY FROM BEING ENCHANTED.
              </h2>
              <div
                class="d-flex justify-content-center align-items-center text-center"
              >
                <img class="me-3" src="../assets/image/flower.svg" alt="" />
                <h3 class="m-0">
                  <em> Mint your Enchanteds Now</em>
                </h3>
                <img
                  class="flipped ms-3"
                  src="../assets/image/flower.svg"
                  alt=""
                />
              </div>
              <div class="gold-line"></div>
            </div>
            <div v-if="!pending" class="container mt-5 mb-5">
              <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-6">
                  <input
                    style="text-align: center"
                    type="number"
                    v-model="amount"
                  />
                  <p class="mt-2">
                    Total price:
                    {{ total }} ETH
                  </p>
                </div>
                <div
                  v-if="
                    amount !== undefined &&
                    amount > 0 &&
                    selectedGroup !== undefined
                  "
                  class="col-12 col-md-6 col-lg-6"
                >
                  <div v-if="!pending" class="btn-web3">
                    <div class="mint-btn" @click="buyWhitelistNFT()">
                      <p>Mint Enchanteds</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-5 text-center">
              <div v-if="pending && !isMinted">
                <p>Trasaction is pending at:</p>
                <a
                  class="pending-tx"
                  :href="'https://etherscan.io/tx/' + pending"
                  target="_blank"
                  >{{ pending }}</a
                >
              </div>
              <div class="mt-2" v-if="pending && !isMinted">
                <i class="fas fa-spinner fa-pulse"></i>
              </div>
              <div class="mt-2 mb-2" v-if="isMinted">
                <p class="mb-4">Your Enchanteds NFT is successfully minted.</p>
                <ShareNetwork
                  network="twitter"
                  url="https://www.enchantedvalleynft.com"
                  title="I minted Enchanteds NFT from Enchanted Valley and now #IAmEnchanted. You can mint while they last, check out the amazing Art, their purpose, team, community, and #GetEnchanted"
                >
                  <div class="btn-web3">
                    <div class="mint-btn">
                      <p>Share on Twitter</p>
                    </div>
                  </div>
                </ShareNetwork>
              </div>
            </div>
          </div>
        </div>
        <!-- END WHITELIST -->

        <!-- PUBLIC SALE
        <div v-if="!whitelistActive && account && isSaleOpen">
          <div>
            <div>
              <h2>
                CONGRATULATIONS !! YOU ARE ONE STEP AWAY FROM BEING ENCHANTED.
              </h2>
              <div
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  text-center
                "
              >
                <img class="me-3" src="../assets/image/flower.svg" alt="" />
                <h3 class="m-0">
                  <em> Mint Your Avatar Now</em>
                </h3>
                <img
                  class="flipped ms-3"
                  src="../assets/image/flower.svg"
                  alt=""
                />
              </div>
              <div class="gold-line"></div>
            </div>
            <div v-if="!pending" class="container mt-5 mb-5">
              <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-6">
                  <input type="number" v-model="amount" />
                  <p class="mt-2">
                    Total price:
                    {{ total }} ETH
                  </p>
                </div>
                <div
                  v-if="
                    amount !== undefined &&
                    amount > 0 &&
                    selectedGroup !== undefined
                  "
                  class="col-12 col-md-6 col-lg-6"
                >
                  <div v-if="!pending" class="btn-web3">
                    <div class="mint-btn" @click="buyNFT()">
                      <p>Mint Enchanteds</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-5 text-center">
              <div v-if="pending && !isMinted">
                <p>Trasaction is pending at:</p>
                <a
                  class="pending-tx"
                  :href="'https://etherscan.io/tx/' + pending"
                  target="_blank"
                  >{{ pending }}</a
                >
              </div>
              <div class="mt-2" v-if="pending && !isMinted">
                <i class="fas fa-spinner fa-pulse"></i>
              </div>
              <div class="mt-2 mb-2" v-if="isMinted">
                <p>Your nft is minted correctly!</p>
              </div>
            </div>
          </div>
        </div> END PUBLIC SALE -->

        <!-- CLAIM ARTIFACT -->
        <div
          class="mb-5"
          v-if="
            account && artifactOwned && artifactBalance > 0 && !artifactsLoaded
          "
        >
          <h2 style="margin-top: 100px">Checking Artifacts</h2>
          <i class="fas fa-spinner fa-pulse"></i>
        </div>

        <div
          class="container"
          v-if="
            account &&
            artifactOwned &&
            artifactBalance > 0 &&
            artifactsLoaded &&
            !loadingState
          "
        >
          <div class="row justify-content-center">
            <h2 class="m-0">
              Artifact holders can mint from The Enchanted Collection as long as
              supplies last.
            </h2>
            <div class="d-flex justify-content-center text-center mt-3">
              <img class="me-3" src="../assets/image/flower.svg" alt="" />
              <h4 class="m-0">
                <em>
                  Have you redeemed your 1 Free NFT (+ gas) per Artifact? Check
                  your redemption status below.</em
                >
              </h4>
              <img
                class="flipped ms-3"
                src="../assets/image/flower.svg"
                alt=""
              />
            </div>
            <div class="gold-line mt-1 mb-1"></div>
            <div
              class="d-flex justify-content-center"
              v-if="claimedArtifact.length > 0"
            >
              <p>You have already redeemed Artifact Token:</p>
              <div class="d-flex">
                <div
                  v-for="tokenClaimed in claimedArtifact"
                  :key="tokenClaimed.id"
                >
                  <p class="ms-2">#{{ tokenClaimed.id }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 col-lg-8 container-artifact">
              <div class="mb-3">
                <div class="row mt-3 artifact-container">
                  <div
                    v-for="tokenId in artifactOwned"
                    :key="tokenId.id"
                    class="col-12 col-md-4 col-lg-3 p-0 my-2"
                  >
                    <div
                      class="nft-selection"
                      v-bind:class="{
                        'active-btn':
                          selectedArtifact.indexOf(tokenId.id) !== -1,
                      }"
                      :id="tokenId.id"
                      @click="toggleArtifacts(tokenId.id)"
                    >
                      #{{ tokenId.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="pendingArtifact" class="mb-5 mt-5 text-center">
              <div v-if="pendingArtifact && !isClaimed">
                <p>Trasaction is pending at:</p>
                <a
                  class="pending-tx"
                  :href="'https://etherscan.io/tx/' + pendingArtifact"
                  target="_blank"
                  >{{ pendingArtifact }}</a
                >
              </div>
              <div class="mt-2" v-if="pendingArtifact && !isClaimed">
                <i class="fas fa-spinner fa-pulse"></i>
              </div>
              <div class="mt-2 mb-2" v-if="isClaimed">
                <p>Your Enchanteds NFT is redeemed successfully</p>
              </div>
            </div>
            <div
              v-if="account && artifactOwned && selectedArtifact.length > 0"
              class="container"
            >
              <div class="btn-web3">
                <div class="mint-btn" @click="claimArtifacts()">
                  <p>Redeem Artifacts</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- END CLAIM ARTIFACT -->

        <!-- END CONTROLS -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// CONTRATTO UFFICIALE IN PRODUZIONE= 0xf4bF7C9b229F1B8728001349918196B344ace80f
// CONTRATTO DI TEST SUITE RINKEBY ATTUALMENTE IN PRODUZIONE= 0x3c3Ef0954Df42a123C4C716CB94403b1C50D2eCa
// CONTRATTO DI TEST SUITE RINKEBY NUOVO= 0xFFb10958173508Cece1C41F4FFc6cbbc286009c4
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { isMobile } from "mobile-device-detect";
import axios from "axios";
const keccak256 = require("keccak256");
const { MerkleTree } = require("merkletreejs");
const ABI = require("../abi.json");
export default {
  name: "BuySingle",
  data() {
    return {
      networks: {
        ethereum: 1,
        rinkeby: 4,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },
      abi: ABI,
      account: "",
      network: "ethereum",
      price: 0.08,
      apiUrl: "https://enchanted-valley-api-kwvlp.ondigitalocean.app",
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      explorerUrl: "https://etherscan.io/tx/",
      pending: "",
      pendingArtifact: "",
      selectedGroup: 0,
      amount: 1,
      total: 0.08,
      isSaleOpen: "",
      tribeActive: "",
      isMinted: false,
      loadingState: true,
      nameTribe: "",
      symbolTribe: "",
      ownerContract: "",
      artifactOwned: [],
      addressList: [],
      toClaim: "",
      selectedArtifact: [],
      claimedArtifact: [],
      singleArtifact: {},
      artifactsLoaded: false,
      isClaimed: "",
      artifactBalance: 0,
      checkIfWhiteListed: "",
      whitelistActive: "",
      loadingWhitelistAddress: true,
      tx: "",
      isTxPengind: false,
      metamaskInstalled: "",
      isMobile,
    };
  },
  watch: {
    amount() {
      const app = this;
      if (app.amount > 10) {
        app.amount = 10;
      }
      if (app.amount <= 0) {
        app.amount = 1;
      }
      app.total = (app.price * app.amount).toFixed(2);
    },
  },
  methods: {
    async connect() {
      const app = this;
      let providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "57d9ea9ca92a4449933c2b7d7145187d",
          },
        },
      };
      console.log("init web3modal");
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      const netId = await app.web3.eth.net.getId();
      if (parseInt(netId) !== app.networks[app.network]) {
        alert("Switch to " + app.network + " network!");
      } else {
        const accounts = await app.web3.eth.getAccounts();
        console.log("Connecting with metamask...");
        if (accounts.length > 0) {
          app.balance = await app.web3.eth.getBalance(accounts[0]);
          app.account = accounts[0];
          app.balance = parseFloat(
            app.web3.utils.fromWei(app.balance, "ether")
          ).toFixed(10);
          app.fetchContractState();
          await app.ferchArtifact();
          app.fetchWhitelist();
        }
      }
    },

    async fetchContractState() {
      const app = this;
      try {
        const nftContract = new app.web3.eth.Contract(app.abi, app.contract);
        app.tribeActive = await nftContract.methods.selling_tribe().call();
        app.isSaleOpen = await nftContract.methods.sale_active().call();
        app.price = await nftContract.methods.minting_price().call();
        app.price = app.web3.utils.fromWei(app.price, "ether");
        console.log("Tribe on sale", app.tribeActive);
        console.log("Is sale open?", app.isSaleOpen);
        console.log("Minting price is:", app.price);
        for (let i = 0; i <= 4; i++) {
          const supply = await nftContract.methods.returnTribeSupply(i).call();
          console.log("Tribe #" + i + " supply is:", supply);
        }
        console.log(
          "Checking active sale or whitelist...",
          app.whitelistActive
        );
        if (app.isSaleOpen === true) {
          console.log("The sale is active, please continue!");
        } else {
          console.log(
            "First you have to start sale, return to dashboard",
            app.isSaleOpen
          );
        }
        if (app.tribeActive === true) {
          console.log("Your nft already selected", app.tribeActive);
        }
        // TO DO - SELECT GROUP NFT 0 <-> 5 - selezionato al momento è lo 0 cambiarlo quando cambiano i gruppi selezioni
        app.selectedGroup = app.tribeActive;
      } catch (e) {
        alert(e.message);
      }
    },

    async fetchWhitelist() {
      const app = this;
      app.loadingWhitelistAddress = true;
      const nftContract = new app.web3.eth.Contract(app.abi, app.contract);
      app.tribeActive = await nftContract.methods.selling_tribe().call();
      app.whitelistActive = await nftContract.methods.whitelist_active().call();
      app.price = await nftContract.methods.minting_price().call();
      app.price = app.web3.utils.fromWei(app.price, "ether");
      console.log("Tribe on sale", app.tribeActive);
      console.log("Is Whitelist active?", app.whitelistActive);
      console.log("Minting price is:", app.price);

      /*for (let i = 0; i <= 4; i++) {
        const supply = await nftContract.methods.returnTribeSupply(i).call();
        console.log("Tribe #" + i + " supply is:", supply);
      }*/
      console.log("Checking active sale or whitelist...");
      if (app.whitelistActive === true) {
        console.log("The Whitelist sale is active, please continue!");
      } else {
        console.log(
          "First you have to start Whitelist sale, return to dashboard",
          app.whitelistActive
        );
      }
      console.log(app.addressList);
      const leaves = app.addressList.map((x) => keccak256(x));
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const proof = tree.getHexProof(keccak256(app.account));
      console.log(proof);
      // check if in whitelist
      try {
        console.log("Artifact balance", app.artifactBalance);
        if (parseInt(app.artifactBalance) === 0) {
          app.checkIfWhiteListed = await nftContract.methods
            .isWhitelisted(proof, app.account)
            .call();
          console.log("This address is whitelisted?", app.checkIfWhiteListed);
          app.loadingWhitelistAddress = false;
          app.loadingState = false;
        } else {
          setTimeout(function () {
            app.loadingWhitelistAddress = false;
            app.loadingState = false;
          }, 1500);
        }
      } catch (e) {
        console.log("Blockchain call errored, whitelist not active");
        app.loadingState = false;
      }
    },

    async ferchArtifact() {
      const app = this;
      app.artifactsLoaded = false;
      const nftContract = new app.web3.eth.Contract(app.abi, app.contract);
      app.artifactBalance = await nftContract.methods
        .artifactBalance(app.account)
        .call();
      console.log("Artifact Balance is:", app.artifactBalance);
      if (app.artifactBalance > 0) {
        app.checkIfWhiteListed = true;
        let owned = await axios.get(app.apiUrl + "/claimed/" + app.account);
        console.log("Questo è quello che ho:", owned.data);
        for (let k in owned.data) {
          let toCheck = owned.data[k];
          if (toCheck.claimed === false) {
            app.artifactOwned.push(toCheck);
          } else {
            app.claimedArtifact.push(toCheck);
          }
        }
        app.artifactsLoaded = true;
        return true;
      } else {
        app.artifactOwned = false;
        return false;
      }
    },

    async toggleArtifacts(id) {
      const app = this;
      if (app.selectedArtifact.indexOf(id) !== -1) {
        let temp = [];
        for (let k in app.selectedArtifact) {
          if (app.selectedArtifact[k] !== id) {
            temp.push(app.selectedArtifact[k]);
          }
        }
        app.selectedArtifact = temp;
      } else {
        app.selectedArtifact.push(id);
        console.log(app.selectedArtifact);
      }
    },

    async claimArtifacts() {
      const app = this;
      app.isClaimed = false;
      const nftContract = new app.web3.eth.Contract(app.abi, app.contract);
      try {
        let receipt = await nftContract.methods
          .claimArtifact(app.selectedArtifact, app.selectedGroup)
          .send({ from: app.account })
          .on("transactionHash", (pendingArtifact) => {
            app.pendingArtifact = pendingArtifact;
          });
        console.log(JSON.stringify(receipt));
        app.isClaimed = true;
        app.selectedArtifact = [];
        app.claimedArtifacts = [];
        app.artifactOwned = [];
        console.log("Fetching Updated Artifacts..");
        app.fetchContractState();
        app.ferchArtifact();
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    },

    async buyWhitelistNFT() {
      const app = this;
      try {
        const nftContract = new app.web3.eth.Contract(app.abi, app.contract, {
          gasLimit: "700000",
        });
        if (app.account !== undefined && app.account.length > 0) {
          const leaves = app.addressList.map((x) => keccak256(x));
          const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
          // let root = tree.getRoot().toString("hex");
          // let storedRoot = await nftContract.methods.MERKLE_ROOT().call();
          // if ("0x" + root === storedRoot) {
          console.log("The address you have insterted is verified");
          let proof = tree.getHexProof(keccak256(app.account));
          const total = (app.amount * app.price).toFixed(2);
          const wei = app.web3.utils.toWei(total, "ether");
          const gasLimit = 100000 + app.amount * 125000;
          console.log("Try to buy NFT");
          const receipt = await nftContract.methods
            .buyNFT(proof, app.selectedGroup)
            .send({
              from: app.account,
              value: wei.toString(),
              gas: gasLimit,
            })
            .on("transactionHash", (pending) => {
              app.pending = pending;
            });
          app.isMinted = true;
          console.log("NFT minted correctly", JSON.stringify(receipt));
          // } else {
          //   alert("Merkle Root doesn't match");
          // }
        } else {
          alert("Make sure that you have inserted address correctly");
        }
      } catch (e) {
        console.log("Minting Failed", JSON.stringify(e.message));
        app.isMinted = false;
      }
    },

    async buyNFT() {
      const app = this;
      app.isMinted = false;
      try {
        let total = app.amount * app.price;
        const wei = app.web3.utils.toWei(total.toString(), "ether");
        const gasLimit = 100000 + app.amount * 125000;
        const nftContract = new app.web3.eth.Contract(app.abi, app.contract, {
          gasLimit: "700000",
        });

        const receipt = await nftContract.methods
          .buyNFT([], app.selectedGroup)
          .send({
            from: app.account,
            value: wei.toString(),
            gas: gasLimit,
          })
          .on("transactionHash", (pending) => {
            app.pending = pending;
          });

        app.isMinted = true;
        console.log("NFT minted correctly", JSON.stringify(receipt));
      } catch (e) {
        alert("Minting Failed", JSON.stringify(e.message));
        app.isMinted = false;
      }
    },

    chekMemaskInstalled() {
      const app = this;
      if (
        typeof window.ethereum !== "undefined" ||
        typeof window.web3 !== "undefined"
      ) {
        app.metamaskInstalled = true;
      }
    },
  },
  async mounted() {
    const app = this;
    this.chekMemaskInstalled();
    let prelist = await axios.get(app.apiUrl + "/prelist");
    app.addressList = prelist.data;
    console.log("Received pre-list", app.addressList);
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fae090;
  border-radius: 10px;
}
</style>
