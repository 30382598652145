var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-mint"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-center"},[(!_vm.account)?_c('div',[_c('h3',{staticClass:"mb-5"},[_vm._v("Please Connect your Wallet")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"btn-web3"},[_c('div',{staticClass:"mint-btn",on:{"click":function($event){return _vm.connect()}}},[_c('p',[_vm._v("Connect Wallet")])])])])]):_vm._e(),(_vm.account && !_vm.isSaleOpen && !_vm.whitelistActive && !_vm.loadingState)?_c('div',{staticClass:"mb-5"},[_c('h2',{staticClass:"m-0"},[_vm._v("Welcome")]),_c('div',{staticClass:"gold-line"}),_vm._m(0)]):_vm._e(),(_vm.whitelistActive && _vm.loadingWhitelistAddress)?_c('div',{staticClass:"mb-5"},[_c('h2',{staticStyle:{"margin-top":"100px"}},[_vm._v("Checking Pre-mint list")]),_c('i',{staticClass:"fas fa-spinner fa-pulse"})]):_vm._e(),(
          _vm.whitelistActive &&
          !_vm.loadingWhitelistAddress &&
          !_vm.checkIfWhiteListed &&
          !_vm.artifactOwned &&
          !_vm.isSaleOpen
        )?_c('div',{staticClass:"mb-5"},[_c('h2',{staticStyle:{"margin-top":"90px"}},[_vm._v(" Looks like you don't own an Artifact or are not on the Enchanted Premint List. Please come back on 7th March when we start Public Mint of the Enchanted Fae ")])]):_vm._e(),(_vm.account && (_vm.isSaleOpen || _vm.whitelistActive))?_c('div',[_c('div',[_vm._m(1),(!_vm.pending)?_c('div',{staticClass:"container mt-5 mb-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticStyle:{"text-align":"center"},attrs:{"type":"number"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('p',{staticClass:"mt-2"},[_vm._v(" Total price: "+_vm._s(_vm.total)+" ETH ")])]),(
                  _vm.amount !== undefined &&
                  _vm.amount > 0 &&
                  _vm.selectedGroup !== undefined
                )?_c('div',{staticClass:"col-12 col-md-6 col-lg-6"},[(!_vm.pending)?_c('div',{staticClass:"btn-web3"},[_c('div',{staticClass:"mint-btn",on:{"click":function($event){return _vm.buyWhitelistNFT()}}},[_c('p',[_vm._v("Mint Enchanteds")])])]):_vm._e()]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"mb-5 mt-5 text-center"},[(_vm.pending && !_vm.isMinted)?_c('div',[_c('p',[_vm._v("Trasaction is pending at:")]),_c('a',{staticClass:"pending-tx",attrs:{"href":'https://etherscan.io/tx/' + _vm.pending,"target":"_blank"}},[_vm._v(_vm._s(_vm.pending))])]):_vm._e(),(_vm.pending && !_vm.isMinted)?_c('div',{staticClass:"mt-2"},[_c('i',{staticClass:"fas fa-spinner fa-pulse"})]):_vm._e(),(_vm.isMinted)?_c('div',{staticClass:"mt-2 mb-2"},[_c('p',{staticClass:"mb-4"},[_vm._v("Your Enchanteds NFT is successfully minted.")]),_c('ShareNetwork',{attrs:{"network":"twitter","url":"https://www.enchantedvalleynft.com","title":"I minted Enchanteds NFT from Enchanted Valley and now #IAmEnchanted. You can mint while they last, check out the amazing Art, their purpose, team, community, and #GetEnchanted"}},[_c('div',{staticClass:"btn-web3"},[_c('div',{staticClass:"mint-btn"},[_c('p',[_vm._v("Share on Twitter")])])])])],1):_vm._e()])])]):_vm._e(),(
          _vm.account && _vm.artifactOwned && _vm.artifactBalance > 0 && !_vm.artifactsLoaded
        )?_c('div',{staticClass:"mb-5"},[_c('h2',{staticStyle:{"margin-top":"100px"}},[_vm._v("Checking Artifacts")]),_c('i',{staticClass:"fas fa-spinner fa-pulse"})]):_vm._e(),(
          _vm.account &&
          _vm.artifactOwned &&
          _vm.artifactBalance > 0 &&
          _vm.artifactsLoaded &&
          !_vm.loadingState
        )?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('h2',{staticClass:"m-0"},[_vm._v(" Artifact holders can mint from The Enchanted Collection as long as supplies last. ")]),_vm._m(2),_c('div',{staticClass:"gold-line mt-1 mb-1"}),(_vm.claimedArtifact.length > 0)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',[_vm._v("You have already redeemed Artifact Token:")]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.claimedArtifact),function(tokenClaimed){return _c('div',{key:tokenClaimed.id},[_c('p',{staticClass:"ms-2"},[_vm._v("#"+_vm._s(tokenClaimed.id))])])}),0)]):_vm._e(),_c('div',{staticClass:"col-12 col-md-8 col-lg-8 container-artifact"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"row mt-3 artifact-container"},_vm._l((_vm.artifactOwned),function(tokenId){return _c('div',{key:tokenId.id,staticClass:"col-12 col-md-4 col-lg-3 p-0 my-2"},[_c('div',{staticClass:"nft-selection",class:{
                      'active-btn':
                        _vm.selectedArtifact.indexOf(tokenId.id) !== -1,
                    },attrs:{"id":tokenId.id},on:{"click":function($event){return _vm.toggleArtifacts(tokenId.id)}}},[_vm._v(" #"+_vm._s(tokenId.id)+" ")])])}),0)])]),(_vm.pendingArtifact)?_c('div',{staticClass:"mb-5 mt-5 text-center"},[(_vm.pendingArtifact && !_vm.isClaimed)?_c('div',[_c('p',[_vm._v("Trasaction is pending at:")]),_c('a',{staticClass:"pending-tx",attrs:{"href":'https://etherscan.io/tx/' + _vm.pendingArtifact,"target":"_blank"}},[_vm._v(_vm._s(_vm.pendingArtifact))])]):_vm._e(),(_vm.pendingArtifact && !_vm.isClaimed)?_c('div',{staticClass:"mt-2"},[_c('i',{staticClass:"fas fa-spinner fa-pulse"})]):_vm._e(),(_vm.isClaimed)?_c('div',{staticClass:"mt-2 mb-2"},[_c('p',[_vm._v("Your Enchanteds NFT is redeemed successfully")])]):_vm._e()]):_vm._e(),(_vm.account && _vm.artifactOwned && _vm.selectedArtifact.length > 0)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"btn-web3"},[_c('div',{staticClass:"mint-btn",on:{"click":function($event){return _vm.claimArtifacts()}}},[_c('p',[_vm._v("Redeem Artifacts")])])])]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center align-items-center text-center"},[_c('img',{staticClass:"me-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}}),_c('h3',{staticClass:"m-0"},[_c('em',[_vm._v("Our Public Sale Starts on March 7th at 7 AM PST.")])]),_c('img',{staticClass:"flipped ms-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" CONGRATULATIONS !! YOU ARE ONE STEP AWAY FROM BEING ENCHANTED. ")]),_c('div',{staticClass:"d-flex justify-content-center align-items-center text-center"},[_c('img',{staticClass:"me-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}}),_c('h3',{staticClass:"m-0"},[_c('em',[_vm._v(" Mint your Enchanteds Now")])]),_c('img',{staticClass:"flipped ms-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}})]),_c('div',{staticClass:"gold-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center text-center mt-3"},[_c('img',{staticClass:"me-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}}),_c('h4',{staticClass:"m-0"},[_c('em',[_vm._v(" Have you redeemed your 1 Free NFT (+ gas) per Artifact? Check your redemption status below.")])]),_c('img',{staticClass:"flipped ms-3",attrs:{"src":require("../assets/image/flower.svg"),"alt":""}})])}]

export { render, staticRenderFns }