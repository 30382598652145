<template>
  <div class="privacy">
    <div class="container mt-5">
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">PRIVACY POLICY</h3>
        </div>
        <div class="col-12">
          <p>
            Your privacy is important to us. This notice explains our online
            information practices and the choices you can make about the way
            your information is used at our Website
            <a href="https://www.enchantedvalleynft.com/" target="_black" style="color:#fae090;"> ( https://www.enchantedvalleynft.com )</a>
            
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Collection of Personal Information</h3>
        </div>
        <div class="col-12">
          <p>
            Enchanted Studios may collect certain personally identifiable
            information (“Personal Information”) about you when you visit our
            Website. Examples of personal information we may collect include
            your name, address, telephone number, fax number, e-mail address. We
            also automatically collect certain non-personally identifiable
            information when you visit our Website, including, but not limited
            to, the type of brow mb-5ser you are using, the type of operating
            system you are using, and the domain name of your Internet service
            provider.
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Use of Personal Information</h3>
        </div>
        <div class="col-12">
          <p>
            Enchanted Studios will only use your personal information where we
            have a lawful basis to do so. We will process your data for the
            following lawful purposes: with your consent; where it is necessary
            for us to perform a contract we have with you, such as sending out
            purchased items / merchandise, or because you have asked us to take
            specific steps before entering into a contract (for example,
            responding to your inquiry ); because the processing is necessary
            for us to comply with the law; or where the processing is necessary
            for our legitimate interests or the legitimate interests of a third
            party. <br />
            We typically will not contact you about other matters, unless you
            specifically request it, nor will we share, rent, or sell your
            personal information outside Enchanted Studios, except where we are
            required to share your information with any third parties who
            provide services on our behalf. <br />
            It is possible, though unlikely, that we might be forced to disclose
            personal information in response to a legal demand or when we
            believe in good faith that the law requires it, for example, in
            response to a law enforcement agency's request. <br />
            We use non-personally identifiable information to analyze site usage
            (such as aggregated information on the pages visited by our users),
            which allows us to improve the design and content of our site.
            <br />
            We cannot guarantee the privacy of personal information you transmit
            over the web or that may be collectible in transit by others,
            including contractors who provide services to Enchanted Studios.
            <br />
            We are committed to complying with all applicable privacy laws and
            regulations. To the extent we receive personal information from a
            resident of the European Union, we will comply with the requirements
            of the General Data Protection Regulation (GDPR). To the extent we
            receive personal information from a resident of California, we will
            comply with the requirements of the California Consumer Privacy Act
            (CCPA) (California Civil Code Section 1798.100 et seq).
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Third Party Sites</h3>
        </div>
        <div class="col-12">
          <p>
            Our Website may contain links to other websites. The data privacy
            practices of those third-party websites might differ from Enchanted
            Studios’s. You should consult the other websites' privacy notices
            for information on their privacy practices as Enchanted Studios has
            no control over information that is submitted to, or collected by,
            these third parties. <br />
            Enchanted Studios may post information on our Website about programs
            and events that are sponsored by or co-sponsored by other
            organizations. Enchanted Studios will have no control over the
            third-party sponsors' use of this information.
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Cookies</h3>
        </div>
        <div class="col-12">
          <p>
            When you visit our Website, we and third parties with whom we have
            contracted to provide services to Enchanted Studios may collect
            "Site Usage Data," which is non-personally identifiable information
            relating to your use of our Website such as what Website pages you
            visit. The Website Usage Data could include your MAC address,
            operating system version, type of computer and browser type. This
            data could also include demographic data, such as a Website
            visitor's geographic location. To enhance your experience on our
            Website, we and our service providers may use "cookies" on our
            Website. <br />
            A "cookie" is a piece of information that a web server may place on
            your computer when you visit a website. Cookies are commonly used by
            websites to improve the user experience and have not been known to
            transmit computer viruses or otherwise harm your computer. Many
            cookies last only through a single website session or visit. Others
            may have an expiration date or may remain on your computer until you
            delete them. <br />
            We may use cookies for a number of purposes - for example, to
            maintain continuity during a user session, to gather Website Usage
            Data for research and other purposes, to store your preferences for
            certain kinds of information and marketing offers, or to store
            certain information so that you do not have to provide this
            information every time you return to one of our Website. Our cookies
            will track only your activity relating to your online activity on
            our Website, and will not track your other Internet activity. Our
            cookies do not gather personal information.
            <br />
            You can decide if and how your computer will accept a cookie by
            configuring your preferences or options in your browser. However, if
            you choose to reject cookies, you may not be able to use certain
            online products and services offered on the Website or certain
            features of our Website. <br />
            Enchanted Studios may occasionally use other companies to set
            cookies on our Website and gather Website Usage Data for Enchanted
            Studios. In some cases, Enchanted Studios may also use another
            company to operate web servers for our Website. Enchanted Studios
            uses the cookie information gathered by these companies in the same
            manner as stated in this Privacy Policy
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">IP Addresses</h3>
        </div>
        <div class="col-12">
          <p>
            When you visit and interact with our Website, Enchanted Studios and
            third parties with whom we have contracted to provide services for
            us may collect Internet Protocol (IP) addresses (each an "IP
            Address"). An IP Address is a number that is automatically assigned
            to the computer that you are using by your Internet Service Provider
            (ISP). Your IP Address is identified and logged automatically
            whenever you visit the Website, along with the time(s) of your
            visit(s), your geographic location and the page(s) that you visited.
            Enchanted Studios uses this information to determine the locations
            of our Website visitors to better understand where our visitors
            reside. Enchanted Studios also may use this information to enhance
            our Website or for other lawful purposes.
            <br />
            Certain pages on our Website contain "web beacons" (also known as
            Internet tags, pixel tags and clear GIFs). These web beacons allow
            third parties to obtain information such as the IP Address of the
            computer that downloaded the page on which the beacon appears, the
            URL of the page on which the beacon appears, the time the page
            containing the beacon was viewed, the type of browser used to view
            the page, and the information in cookies set by the third party.
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Legal Rights of European Residents</h3>
        </div>
        <div class="col-12">
          <p>
            Some data protection laws, including GDPR, provide you with certain
            rights in connection with personal information you have shared with
            us. If you are resident in the European Economic Area, you may have
            the following rights:
            <ul>
                <li>
                    The right to be informed: You are entitled to be informed of the use of
                    your personal information. This Privacy Policy provides such information to you.
                </li>
                <li>
                    The right of access: You have the right to request a copy of your personal
                    information which we hold about you
                </li>
                <li>
                    The right of correction: You have the right to request correction or
                    changes of your personal information if it is found to be inaccurate or out of date.
                </li>
                <li>
                    The right to be forgotten: You have the right to request us, at any time, to
                    delete your personal information from our servers and to erase your personal information
                    when it is no longer necessary for us to retain such data. Note, however, that deletion of
                    your personal information will likely impact your ability to use our services.
                </li>
                <li>
                    The right to object (opt-out): You have the right to opt-out of certain uses
                    of your personal information, such as direct marketing, at any time.
                </li>
                <li>
                    The right to data portability: You have the right to a “portable” copy of
                    your personal information that you have submitted to us. Generally, this means your
                    right to request that we move, copy or transmit your personal information stored on our
                    servers / IT environment to another service provider’s servers / IT environment.
                </li>
                <li>
                    The right to refuse to be subjected to automated decision making,
                    including profiling: You have the right not to be subject to a decision and insist on human
                    intervention if the decision is based on automated processing and produces a legal effect
                    or a similarly significant effect on you.
                </li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Contacting Us</h3>
        </div>
        <div class="col-12">
          <p>
            If you have any questions or concerns about this Privacy Policy or its implementation, you may
            contact us at <a style="color:#fae090;" href="mailto:info@enchantedvalleynft.com">info@enchantedvalleynft.com</a>
          </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h3 class="text-center mb-3">Revisions to our Privacy Policy</h3>
        </div>
        <div class="col-12">
          <p>
            We reserve the right to revise this policy or any part of it from time to time. Please review the
            policy periodically for changes.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style></style>
