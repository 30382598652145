<template>
    <swiper :options="swiperOptions" class="swiper">
      <swiper-slide>
        <div class="box-rar">
            <img src="../assets/image/prima.png" alt="" />
            <div class="descr-fat">
                <p>one fae-jinn</p>
            </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="box-rar">
            <img src="../assets/image/seconda.png" alt="" />
            <div class="descr-fat">
                <p>ENCHANTED LOCKETS</p>
            </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="box-rar">
            <img src="../assets/image/terza.png" alt="" />
            <div class="descr-fat">
                <p>FAE COMPANIONS</p>
            </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="box-rar">
            <img src="../assets/image/quarta.png" alt="" />
            <div class="descr-fat">
                <p>RARE FAE TRAITS</p>
            </div>
        </div>
      </swiper-slide>
    </swiper>  
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "slideRare",
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        loopFillGroupWithBlank: true,
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
    .swiper-slide{
        padding: 10px;
    }
</style>>