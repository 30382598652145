<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 bk-banner-lore">
          <p>The Enchanted Allies</p>
        </div>
      </div>
    </div>
    <div class="container allies">
      <swiper
        class="swiper team-slider ps-4 pe-4"
        ref="swiper"
        :options="swiperOption"
      >
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-soulcurryart.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">SOULCURRYART</h4>
            <div class="text-center">
              <a href="https://twitter.com/soulcurryart" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-sabet.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">SABET</h4>
            <div class="text-center">
              <a href="https://twitter.com/sabet" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-kindredhearts.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">KINDRED HEARTS</h4>
            <div class="text-center">
              <a href="https://twitter.com/KindredHeartsIO" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-fintist.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">THE DIVINES</h4>
            <div class="text-center">
              <a href="https://twitter.com/thedivinenft" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-afrodroids.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">OWO AFRODROIDS</h4>
            <div class="text-center">
              <a href="https://twitter.com/AfroDroids" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-stargazer.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">STARGAZER</h4>
            <div class="text-center">
              <a href="https://twitter.com/stargaz69927603" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-ash.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">ASH</h4>
            <div class="text-center">
              <a href="https://twitter.com/ashcooperkerns" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-ethereals.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">THE ETHEREALS</h4>
            <div class="text-center">
              <a href="https://twitter.com/Etherealswtf" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-shroom.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">SHROOM FOLK</h4>
            <div class="text-center">
              <a href="https://twitter.com/ShroomFolkNFT" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="image-allies">
            <img src="../assets/image/avatar-putnam.png" />
            <div class="partners"></div>
            <h4 class="profile mt-1">MITCH PUTNAM</h4>
            <div class="text-center">
              <a href="https://twitter.com/mitchputnam" target="_blanck">
                <img class="twt" src="../assets/image/Vector (6).svg" />
              </a>
            </div>
          </div>
        </swiper-slide>

        <div
          style="outline-style: none"
          class="swiper-button-prev"
          slot="button-prev"
        >
          <img class="freccial-sx" src="../assets/image/Group 143.svg" alt="" />
        </div>
        <div
          style="outline-style: none"
          class="swiper-button-next"
          slot="button-next"
        >
          <img class="freccial-dx" src="../assets/image/Group 142.svg" alt="" />
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Allies",
  data() {
    return {
      swiperOption: {
        breakpoints: {
          1999: {
            slidesPerView: 5,
            spaceBetween: 35,
          },
          1279: {
            slidesPerView: 5,
            spaceBetween: 30,
          },

          1000: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          240: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
.allies {
  .team-slider {
    .swiper-button-next {
      right: -12px !important;
      .freccial-dx {
        width: 25px;
      }
    }
    .swiper-button-prev {
      left: -12px !important;
      .freccial-sx {
        width: 25px;
      }
    }
  }
}
.image-allies {
  position: relative;
  width: 100%;
  padding: 0px 12px;
  margin: auto;
  img {
    width: 100%;
    padding: 0px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .twt {
    width: 30px;
    height: 30px;
    margin-bottom: 7px;
  }
  .profile {
    text-align: center;
    border: 1px solid #fae090;
    color: #fae090;
    font-family: Abhaya Libre;
    padding: 3px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }
}
@media screen and (max-width: 467px) {
  .allies{
    .team-slider{
      .swiper-button-prev{
        left: -17px!important;
      }
      .swiper-button-next{
        right: -17px!important;
      }
    }
  }
  .image-allies {
    padding: 0px 10px;
    .twt {
      width: 24px;
      height: 24px;
      margin-bottom: 7px;
    }
    .profile {
      padding: 2px 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
</style>
