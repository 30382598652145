<template>
  <div class="list-item-container">
    <div class="list-item-head p-8">
      <p class="domanda">
        <img style="width: 25px; margin-right:5px; padding-bottom:3px" src="../assets/image/emoji_star.svg" alt="">
       {{ user.question }}
      </p>
      <span class="toggle float-right" @click="user.visible = !user.visible">
        <img
          id="arrow"
          src="../assets/image/arrow-down.svg"
          alt=""
        />
      </span>
    </div>
    <div class="list-item-body p-8 risposta" v-show="user.visible">
        <p class="risposta-dim">
           <slot />
        </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user"],
  
  
};
</script>
<style scoped lang="scss">
/* .ruota {
  transform: rotate(180deg);
  animation: left-of-animation 0.35s 1;
  @keyframes left-of-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
} */

</style>
