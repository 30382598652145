<template>
  <div>
    <div class="col-12">
      <div class="box-fata">
        <div class="bk-name">
          <p>Fae - Land Order</p>
        </div>
        <div class="img-fate">
          <img src="../assets/image/land.webp" alt="" />
        </div>
        <!-- TODO: show when the sale is enabled -->
        <!-- <div class="bk-pieno" @click="showModalSale()">
          <p>Mint A Fae</p>
        </div> -->
        <!-- <p class="text-center mt-2">{{ tribes[0] }}/2200</p> -->
      </div>
    </div>
    <div class="col-12">
      <swiper :options="swiperOptions" class="swiper">
        <swiper-slide>
          <div class="box-fata">
            <div class="bk-name">
              <p>Jinn - Fire Order</p>
            </div>
            <div class="img-fate">
              <img src="../assets/image/fire.webp" alt="" />
            </div>
            <div class="bk-vuoto">
              <p>Coming Soon</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box-fata">
            <div class="bk-name">
              <p>Nymph - Water Order</p>
            </div>
            <div class="img-fate">
              <img src="../assets/image/water.webp" alt="" />
            </div>
            <div class="bk-vuoto">
              <p>Coming Soon</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box-fata">
            <div class="bk-name">
              <p>Ephemera - Air Order</p>
            </div>
            <div class="img-fate">
              <img src="../assets/image/air.webp" alt="" />
            </div>
            <div class="bk-vuoto">
              <p>Coming Soon</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box-fata">
            <div class="bk-name">
              <p>Mystery Order</p>
            </div>
            <div class="img-fate">
              <img src="../assets/image/cosmos.webp" alt="" />
            </div>
            <div class="bk-vuoto">
              <p>Coming Soon</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "slideCooming",
  data() {
    return {
      tribes: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      swiperOptions: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        loopFillGroupWithBlank: true,
      },
    };
  },
  async mounted() {
    const app = this;
    let minted = await axios.get(process.env.VUE_APP_API_ENDPOINT + "/info");
    app.tribes = minted.data;
    console.log("Received minted data:", app.tribes);
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    showModalSale() {
      this.$modal.show("mintingfae");
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
}
</style>
>
