<template>
  <div class="menu-bar">
    <Push :right="true" :closeOnNavigation="true">
      <a
        style="color: #ffd65e"
        href="https://www.cryptovoxels.com/play?coords=SE@1020E,26N,6F"
        target="_blank"
      >
        <img src="../assets/image/style-list.svg" alt="" />
        <span class="">The Metaverse</span>
      </a>
      <a style="color: #ffd65e" href="#rarities">
        <img src="../assets/image/style-list.svg" alt="" />
        <span class="">Rarities</span>
      </a>
      <a
        style="color: #ffd65e"
        href="https://medium.com/enchanted-valley"
        target="_blank"
      >
        <img src="../assets/image/style-list.svg" alt="" />
        <span class="">The Blog</span>
      </a>
      <!-- <a style="color: #ffd65e" href="#artifact">
        <img src="../assets/image/style-list.svg" alt="" /> <span class="">Partners</span>
      </a> -->
      <!-- <a style="color: #ffd65e" href="#team">
        <img src="../assets/image/style-list.svg" alt="" /> <span class="">Muses Choosen</span>
      </a> -->
      <!-- <a style="color: #ffd65e" href="#roadmap">
        <img src="../assets/image/style-list.svg" alt="" /> <span class="">Purpose</span>
      </a> -->
      <a
        style="color: #ffd65e"
        href="https://discord.gg/enchantedvalley"
        target="_black"
      >
        <img src="../assets/image/style-list.svg" alt="" />
        <span class="">Contact Us</span>
      </a>

      <div>
        <p style="cursor: pointer; color: #fae090" @click="showModalTerms()">
          <img class="me-1" src="../assets/image/style-list.svg" alt="" />
          <span class="">Terms of Service</span>
        </p>
        <div
          ref="modal"
          class="modal fade"
          :class="{ show, 'd-block': active }"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 style="color: #fae090" class="modal-title">DISCLAIMER</h5>
                <button
                  type="button"
                  class="btn btn-dark"
                  style="padding: 0.01rem 0.55rem"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="toggleModal"
                >
                  <span
                    style="font-size: 1.2rem; color: #fae090"
                    aria-hidden="true"
                    >&times;</span
                  >
                </button>
              </div>
              <!-- contenuto modal -->
              <div class="modal-body">
                <div class="privacy">
                  <div class="container mt-5">
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">1. General Disclaimer</h3>
                      </div>
                      <div class="col-12">
                        <p>
                          By using our Site
                          <a
                            href="https://www.enchantedvalleynft.com/"
                            target="_black"
                            style="color: #fae090"
                          >
                            (https://www.enchantedvalleynft.com)</a
                          >
                          you are agreeing to be bound by following terms and
                          conditions (“Terms). Enchanted Studios and other
                          affiliated websites provide (the “Sites”) as a service
                          to the public. Enchanted Studios and its affiliated
                          sites are not responsible for, and expressly disclaims
                          all liability for, damages of any kind arising out of
                          use, reference to, or reliance on any information
                          contained within this website. While the information
                          contained within this website is periodically updated,
                          no guarantee is given that the information provided in
                          this website is correct, complete, and up-to-date.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">
                          2. Limitation of Liability
                        </h3>
                      </div>
                      <div class="col-12">
                        <p>
                          2.1 To the maximum extent permitted by applicable law,
                          in no event shall the Sites or their agents be liable
                          for any direct, indirect, special, punitive,
                          incidental, exemplary, or consequential damages, or
                          any damages whatsoever resulting from any loss of use,
                          loss of data, loss of profits, business interruption,
                          litigation, or any other pecuniary loss, whether based
                          on breach of contract, tort (including negligence),
                          product liability, or otherwise arising out of or in
                          any way connected with the use, operation, or
                          performance of the service, with the delay or
                          inability to use the service, any defects in the
                          service, or with the provision of, or failure to make
                          available, any information, services, products,
                          materials, or other resources available on or
                          accessible through the service, even if advised of the
                          possibility of such damages.
                          <br /><br />
                          2.2 The operation of the service – whether by the
                          Sites, or their vendors – may not be secure. Security
                          and privacy risks cannot be eliminated. Password
                          protection and any other security measures may not
                          prevent unauthorised access to materials.
                          <br /><br />
                          2.3 With respect to products, or services purchased
                          through the Sites and vendors’ sites, in no event
                          shall the Sites be liable for any direct, indirect,
                          special, punitive, incidental, exemplary, or
                          consequential damages, or any damages whatsoever,
                          resulting from any loss of use, loss of profits,
                          litigation, or any other pecuniary loss, whether based
                          on breach of contract, tort (including negligence),
                          product liability, or otherwise, arising out of or in
                          any way connected with the provision of or failure to
                          make available any such products, goods, or services,
                          even if advised of the possibility of such damages.
                          <br /><br />
                          2.4 Furthermore, the Sites explicitly disclaims any
                          responsibility for the accuracy, content, or
                          availability of information found on sites that link
                          to or from the site from third parties. We cannot be
                          held responsible for the accuracy, copyright
                          compliance, legality, or decency of material contained
                          in third-party sites, and you hereby irrevocably waive
                          any claim against us with respect to such sites.
                          <br /><br />
                          2.5 Under no circumstances shall we or any other party
                          involved in creating, producing, or distributing the
                          site be liable for any direct, indirect, incidental,
                          special, or consequential damages for loss of profits,
                          good will, use, data or other intangible losses (even
                          if we have been advised of the possibility of such
                          damages) that result from (i) the use of or inability
                          to use the site, (ii) the cost of procurement of
                          substitute goods and services resulting from any data,
                          information or services purchased or obtained or
                          messages received or transactions entered into through
                          or from the site; (iii) unauthorised access to or
                          alteration of your registration information,
                          transmissions or data; (iv) statements or conduct of
                          any third party on the site; (v) negligent or willful
                          acts of the Sites, its representatives or (vi) any
                          other matter relating to the site.
                          <br /><br />
                          2.6 You agree to indemnify and hold the Sites and
                          representatives harmless from any claim or demand,
                          including reasonable legal fees, made by any you or
                          any third party due to or arising out of the content
                          you submit, post to or transmit through the site, your
                          use of the site, your connection to the site, your
                          violation of the terms of use, or your violation of
                          any rights of another.
                          <br /><br />
                          2.7 You acknowledge and agree that the limitations set
                          forth above are fundamental elements of this agreement
                          and the service would not be provided to you absent
                          such limitations.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">
                          3. Intellectual Property
                        </h3>
                      </div>
                      <div class="col-12">
                        <p>
                          3.1 All rights in and to the Content and the Sites
                          belong to Enchanted Studios, affiliated sites, or our
                          third party content providers and are protected by the
                          Intellectual Property laws of the UK, US and other
                          countries. We may license third parties to use the
                          Content at our sole discretion.
                          <br /><br />
                          3.2 Any trademarks/logos appearing on the Sites may
                          belong to Enchanted Studios, affiliated sites, or
                          third parties. All product and company names are
                          trademarks or registered trademarks of their
                          respective holders. Use of them does not imply any
                          affiliation with or endorsement by them. None of these
                          mark may be used without the express prior written
                          permission of the respective owners.
                          <br /><br />
                          3.3 Save for where provided otherwise in these Terms,
                          distributing, sharing, copying, posting, scanning,
                          forwarding, selling, publishing, retransmitting or
                          otherwise providing access to any part of the Content
                          to anyone without our express prior written
                          permission, is not authorised and may be a violation
                          of national copyright laws, including without
                          limitation 17 USC 101 et seq, and the UK Copyright,
                          Designs and Patents Act 1988.
                          <br /><br />
                          3.4 For the avoidance of doubt, where the provisions
                          of any applicable law prohibit the extent to which
                          certain activities can be restricted under this Clause
                          3, any such restrictions shall apply to the fullest
                          extent permitted in accordance with such law.
                          <br /><br />
                          3.5 You may use the Sites and the Content solely for
                          your own personal use and benefit and not for resale
                          or other transfer or disposition to any other person
                          or entity. You may not use, reproduce, modify,
                          transfer, exploit, distribute or dispose of any
                          Content for any commercial purposes, whether direct or
                          indirect, in any manner that might compete with our
                          business (including, without limitation, by making
                          Content available to anyone). <br /><br />
                          3.6 Subject to the limitations in Clause 3.5 above,
                          you may retrieve and display Content on a computer
                          screen or other digital device, print a single copy of
                          individual pages on paper (but not photocopy them) and
                          store such pages in electronic form on disc or other
                          digital media (but not on any server or other storage
                          device connected to a network).
                          <br /><br />
                          3.7 Notwithstanding Clause 3.5, you may quote from
                          extracts of the Content to the extent permitted and
                          strictly in accordance with any applicable Specific
                          Terms, or as otherwise permitted by law, on the
                          condition that appropriate source and copyright
                          attribution is given in each case.
                          <br /><br />
                          3.8 Any request for permission to republish, reprint
                          or use any articles from any of the Sites or our
                          trademarks for any purpose other than those permitted
                          under this Clause 3 (or any relevantSpecific Terms)
                          should be sent to enchantedvalleynft@gmail.com.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">4. Your Obligations</h3>
                      </div>
                      <div class="col-12">
                        <p>
                          4.1 The Sites are not intended for users of under
                          thirteen (13) years of age, and such individuals are
                          not permitted to be Users. If individuals under
                          thirteen (13) years of age wish to view Content on the
                          Sites as a Visitor, their parent or guardian must
                          agree to these Terms on their behalf.
                          <br /><br />
                          4.2 You represent and warrant that: (a) you have the
                          capacity to agree to these Terms; and (b) you are at
                          least thirteen (13) years of age.
                          <br /><br />
                          4.3 You are responsible for procuring the necessary
                          equipment and the payment of telephone, internet and
                          other charges necessary to access and/or use any Site
                          or Service. We are not responsible for the reliability
                          or continued availability of the telephone lines,
                          internet and equipment you use to access and/or use a
                          Site or a Service. <br /><br />
                          4.4 Any Content sent for overseas delivery (including,
                          without limitation, any Content for delivery outside
                          the European Union) may be subject to import duties
                          and taxes collected at the final destination. All
                          sales or use taxes, import duties or additional
                          customs charges must be borne entirely by you. We have
                          no control over these charges and we expressly
                          disclaim any responsibility or liability for them.
                          Customs policies vary widely from country to country,
                          so you should contact your local customs office for
                          further information. <br /><br />
                          4.5 Additionally, when ordering from our Sites, you
                          are considered the importer of record and must comply
                          with all law and regulations of the country in which
                          you are receiving the goods. International customers
                          should be aware that cross-border deliveries are
                          subject to opening and inspection by customs
                          authorities. We may provide certain order, delivery
                          and product information (such as the product's title)
                          to our international carriers and such information may
                          be communicated by the carriers to customs authorities
                          in order to facilitate customs clearance and comply
                          with local laws.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">5. Content by Email</h3>
                      </div>
                      <div class="col-12">
                        <p>
                          5.1 We may give you the option to request Content by
                          email. We or our Third Party Service providers may
                          also send you emails to keep you updated on
                          developments in the Sites and Services. Your
                          registration for and/or subscription to such an email
                          Service and your use of the Content received through
                          these email Services will be subject to these Terms.
                          <br /><br />
                          5.2 For the avoidance of doubt, although you may opt
                          out of receiving promotional messages under Clause
                          9.1, we retain the right to send you informational
                          email messages about your account or administrative
                          notices regarding the Sites, as permitted under the
                          CAN-SPAM Act (15 U.S.C. §7701 et seq.) and other
                          applicable laws.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">
                          6. Advertisements, Links and Third Party Transactions
                        </h3>
                      </div>
                      <div class="col-12">
                        <p>
                          6.1 A Site or a Service may contain: (a) links to
                          External Sites. External Sites may advertise or
                          provide the opportunity for you to purchase Third
                          Party Services; and/or (b) advertisements for, and/or
                          the opportunity for you to purchase Third Party
                          Services or products or services from us.
                          <br /><br />
                          6.2 The content of External Sites and Third Party
                          Services is not maintained or controlled by us and we
                          are therefore not responsible for the availability,
                          content or accuracy of External Sites or Third Party
                          Services. We do not (a) make any warranty, express or
                          implied, with respect to the use of the links provided
                          on, or to, a Site or Service; (b) guarantee the
                          accuracy, completeness, usefulness or adequacy of
                          External Sites or Third Party Services; or (c) make
                          any endorsement, express or implied, of any External
                          Sites or Third Party Services. Any advertisement or
                          promotion on a Site of a Third Party Service is not an
                          inducement by us for you to enter into any agreement
                          with the relevant third party.
                          <br /><br />
                          6.3 Any communications or contracts relating to Third
                          Party Services, payment for and delivery of Third
                          Party Services, and any other associated terms,
                          conditions, warranties or representations are
                          exclusively between you and the relevant third
                          parties. You agree not to hold us liable for any loss
                          or damage incurred as the result of any such
                          contracts, communications or other dealings or the
                          presence of such third parties on any Site.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-12">
                        <h3 class="text-center mb-3">
                          7. No Guarantees or Investments
                        </h3>
                      </div>
                      <div class="col-12">
                        <p>
                          7.1 With respect to products, or services purchased
                          through the Sites and vendors’ sites, you agree that
                          the product or service is all you are guaranteed to
                          receive in exchange for your purchase. Any future
                          benefits are ancillary to this purchase and should not
                          be taken into consideration when making your initial
                          purchase. By using this Sites, you agree that you are
                          not relying on any future commitments by Enchanted
                          Studios, affiliated sites, or any other affiliated
                          party when purchasing any products.
                          <br /><br />
                          7.2 NFTs are intended as collectible items, not as
                          investments. You agree that Enchanted Studios and any
                          affiliated sites make absolutely no promise or
                          guarantee that the NFT you purchase is worth more than
                          what you and the market deem it to be worth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /contenuto modal -->
            </div>
          </div>
        </div>
        <div v-if="active" class="modal-backdrop fade show"></div>
      </div>

      <a @click="showModalTerms()" target="_blank" style="color: #fae090">
        <img class="me-1" src="../assets/image/style-list.svg" alt="" />
        Terms of Use
      </a>
      <!-- <a class="mt-4" style="color: #ffd65e; font-size: 0.7rem" href="/disclaimer" target="_black">
            ✧ <span class="">Terms of Service</span>
          </a> -->
      <ul class="nav-secondMenu">
        <li class="d-flex">
          <img class="me-2" src="../assets/image/style-list.svg" alt="" />
          <loreModel />
        </li>
        <li>
          <img class="me-2" src="../assets/image/style-list.svg" alt="" />
          <a href="#team">Team</a>
        </li>
        <li>
          <img class="me-2" src="../assets/image/style-list.svg" alt="" />
          <a href="#roadmap">Roadmap</a>
        </li>
      </ul>
      <ul class="social-hd social-secondMenu">
        <li>
          <a href="https://twitter.com/EnchantedNFTs" target="_black">
            <img class="img-social" src="../assets/image/tw.svg" alt="" />
          </a>
        </li>
        <li>
          <a href="https://discord.com/invite/mP5kJTaD7H" target="_black">
            <img
              class="img-social"
              src="../assets/image/icon-disc.svg"
              alt=""
            />
          </a>
        </li>
        <li>
          <a
            href="https://opensea.io/collection/the-enchanteds"
            target="_black"
          >
            <img class="img-social" src="../assets/image/nav.svg" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/enchantedvalleynft/"
            target="_black"
          >
            <img class="img-social" src="../assets/image/ist.svg" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://etherscan.io/address/0xf4bF7C9b229F1B8728001349918196B344ace80f"
            target="_black"
          >
            <img
              class="img-social"
              src="../assets/image/Group (7).svg"
              alt=""
            />
          </a>
        </li>
      </ul>

      
      <!-- TODO: show when the sale is enabled -->
      <!-- <a
        @click="showModalSale()"
        style="text-decoration: none; margin-right: 20px"
        ><div class="new-mint-btn hideDesktop">Mint</div></a
      > -->


      <!-- <a
          style="text-decoration: none; margin-right: 20px"
          ><div class="new-mint-btn-tp hideDesktop">Coming Soon</div></a
        > -->
    </Push>
    <modal name="termofuse" class="modal-terms">
      <TermofUse />
    </modal>
  </div>
</template>

<script>
import { Push } from "vue-burger-menu";
import loreModel from "@/components/loreModel.vue";
import TermofUse from "@/components/TermofUse.vue";

export default {
  name: "secondMenu",
  components: {
    Push,
    loreModel,
    TermofUse,
  },
  data() {
    return {
      active: false,
      show: false,
    };
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      setTimeout(() => (this.show = !this.show), 10);
    },

    showModalSale() {
      this.$modal.show("mintingfae");
    },
    showModalTerms() {
      this.$modal.show("termofuse");
    },
  },
};
</script>

<style lang="scss">
.modal-terms.vm--container {
  padding: 20px 0;
  .vm--modal {
    width: 80% !important;
  }
}

@media screen and (max-width: 767px) {
  .modal-terms.vm--container {
    padding: 20px 0 40px 0;
    .vm--modal {
      width: 90% !important;
    }
  }
}
</style>
