<template>
  <section class="artifact new-artifact">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 order-2 order-lg-1">
          <div class="about-artifact">
            <vue-aos animation-class="animate__animated animate__slideInUp">
              <div class="frame-artifact">
                <h3>The Enchanted Artifact</h3>
                <p class="mb-3">
                  The mysterious, ancient Artifact spins slowly, hovering above
                  a temple built to protect it at Enchanted Valley’s sacred
                  centerpoint. It hums as if alive, acting as the valley’s sun
                  and its life-force, spreading light and enchantment to the
                  farthest reaches of the land.
                </p>

                <!-- <ul>
                  <li><img class="me-1 pb-1" src="../assets/image/style-list.svg" alt="" />One Cryptovoxels wearable</li>
                  <li>
                    <img class="me-1 pb-1" src="../assets/image/style-list.svg" alt="" /> Access to events in our oceanfront Cryptovoxels gallery
                  </li>
                  <li><img class="me-1 pb-1" src="../assets/image/style-list.svg" alt="" /> Special role in the Enchanted Valley discord</li>
                  <li><img class="me-1 pb-1" src="../assets/image/style-list.svg" alt="" /> Animated video version of the Artifact</li>
                </ul> -->
                <p class="mb-3">
                  A limited set of 500 Artifact NFTs were released in Feb 2022.
                  Holders of the Artifact belong to an exclusive collectors
                  group, with access to free NFTs, airdrops, permanent premint
                  status, gated discord channels & more.
                </p>
                <p class="">
                  Each Artifact allows for ONE FREE mint + gas from The
                  Enchanteds Collection. If you are planning to buy an Artifact
                  from the secondary market, you can enter the Token ID below to
                  check if it has already been redeemed by the previous owner.
                </p>
                <!-- TODO: show when the sale is enabled -->
                <!-- <div class="btn-head mt-4">
                  <button @click="showModalSale()">Claim your Artifacts</button>
                </div> -->
              </div>
            </vue-aos>

            <div class="mb-3 input-token">
              <div class="d-flex justify-content-between">
                <input
                  type="text"
                  v-model="artifact"
                  id="artifacts-check"
                  placeholder="Enter Your Artifact Token ID"
                />
                <div id="emailHelp" class="form-text"></div>
                <button v-if="!isChecking" v-on:click="checkArtifact">
                  GO
                </button>
              </div>
              <div class="results">
                <div>
                  <div v-if="isChecking">
                    <i class="fas fa-spinner fa-pulse"></i> Checking, please
                    wait...
                  </div>
                  <div v-if="checked && claimed">This Token is Redeemed!</div>
                  <div v-if="checked && !claimed">
                    This Token isn't Redeemed!
                  </div>
                </div>
                <a
                  :href="
                    'https://opensea.io/assets/0x2a8555f5cd838356295637b336c90368ea1ed995/' +
                    artifact
                  "
                  target="_black"
                >
                  <img
                    class="img-social"
                    src="../assets/image/nav.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <vue-aos animation-class="animate__animated animate__slideInRight">
          <div class="col-12 col-md-12 col-lg-6 order-1 order-lg-2 pt-4">
            <div class="video-container">
              <img src="../assets/image/cornice-movie.svg" alt="" />
              <video
                preload="auto"
                autoplay
                muted
                defaultMuted
                playsinline
                loop
              >
                <source src="/assets/video-artifact.mp4" type="video/mp4" />
              </video>
            </div>

            <!-- <div id="mint" class="mint-btn-container">
              <MintButton :ABI="abi" :price="price" :contract="contract" />
            </div> -->
          </div>
        </vue-aos>
      </div>
    </div>
  </section>
</template>

<script>
/* import MintButton from "@/components/MintButton.vue"; */
const axios = require("axios");
export default {
  name: "Artifact",
  data() {
    return {
      isChecking: false,
      artifact: "",
      claimed: "",
      checked: false,
    };
  },
  methods: {
    showModalSale() {
      this.$modal.show("mintingfae");
    },
    async checkArtifact() {
      const app = this;
      if (app.isChecking === false && parseInt(app.artifact) > 0) {
        app.isChecking = true;
        app.checked = false;
        try {
          let check = await axios.get(
            process.env.VUE_APP_API_ENDPOINT + "/artifact/" + app.artifact
          );
          app.claimed = check.data;
          app.checked = true;
          app.isChecking = false;
        } catch {
          app.isChecking = false;
          alert("Something goes wrong, please retry.");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-head button {
  &:hover {
    background-color: #fae090;
    color: 070032;
  }
}
</style>
