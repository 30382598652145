<template>
  <div id="app">
    <div id="nav">
      <router-view> </router-view>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/scss/style.scss";
@import "assets/scss/resp.scss";

</style>

<script>
export default {
  /* mounted() {
    document.querySelector("body").style.overflowY = "hidden";
  }, */
};
</script>

