import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueTypedJs from "vue-typed-js";
import "animate.css";
import Textra from "vue-textra";
import * as VueAos from "vue-aos";
import vmodal from "vue-js-modal";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueAos);
Vue.use(VueTypedJs);
Vue.use(Textra);
Vue.use(vmodal);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
